import React, {FC} from 'react';
import {observer} from 'mobx-react';

import {Auth} from '../components/Auth';
import {LayoutOuter} from '../components/LayoutOuter';
import {useState} from '../components/Store';

interface MainProps {};

export const Main: FC<MainProps> = observer(
  () => {
    const appState = useState();
    const {main} = appState.lang.lang;
    return (
      <LayoutOuter>
        <div className="content_main">
          <div className="about_tree">
              <h1>{main.tree}</h1>
              <p className="about-text">{main.text}</p>
              <div className="content-app">
                  <p className="down-app">{main.download}</p>
                  <div className="wrap_app">
                      <a target="_blank" href="https://apps.apple.com/us/app/eshejire/id1434530774#?platform=iphone"><img src="/images/appstore.png" alt="" /></a>
                      <a target="_blank" href="https://play.google.com/store/apps/details?id=kz.eshezire"><img src="/images/googlуplay.png" alt="" /></a>
                  </div>
              </div>
          </div>
          <Auth/>
        </div>
      </LayoutOuter>
    );
  }
);
