import React from 'react';

import {LayoutInner} from '../components/LayoutInner';
import {Menu} from '../components/Menu';

export const Error: React.FC = () => {
  return (
    <LayoutInner>
      <section className="content-article">
        <div className="wrap">
          <Menu />
          <div className="main-article-content">
            <h1 className="news-title">404</h1>
          </div>
        </div>
      </section>
    </LayoutInner>
  );
}
