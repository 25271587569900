import React, {FC} from 'react';
import {observer} from 'mobx-react';

import {FooterMenu} from './FooterMenu';
import {useState} from './Store';

interface FooterProps {};

export const Footer: FC<FooterProps> = observer(
  () => {
    const appState = useState();
    const isAuth = appState.auth.isAuth;
    const {footer} = appState.lang.lang;
    return (
      <section className="footer">
        <div className="wrap-footer">
          {isAuth ? <FooterMenu/> : null}
        </div>
        <div className="wrap">
          <div className="footer-info">
            <div className="logo-footer">
              <img src="/images/logo-simbol-2.png" alt="" />
            </div>
            <a href="https://wa.me/77774846323"><p className="footer-number-phone"><img className="img-icon" src="/images/icon-phon-body.png" alt="" />+7 (7212) 43-38-66</p></a>
            <a className="btn-send-footer" href="mailto:info@eshejire.kz">{footer.write}</a>
            <div className="wrap_app_footer">
              <a target="_blank" href="https://apps.apple.com/us/app/eshejire/id1434530774#?platform=iphone"><img src="/images/app-g.png" alt="" /></a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=kz.eshezire"><img src="/images/app.png" alt="" /></a>
            </div>
          </div>
  
          <div className="footer-social">
            <p className="copyright">	&copy; eShejire 2019</p>
            <div className="social_icon">
              <a target="_blank" href="https://vk.com/eshejire"><img src="/images/icon-vk.png" alt="" /></a>
              <a target="_blank" href="https://www.facebook.com/eShejire/"><img src="/images/icon-fb.png" alt="" /></a>
              <a target="_blank" href="https://www.instagram.com/eshejire/"><img src="/images/icon-inst.png" alt="" /></a>
            </div>
          </div>
        </div>
      </section>
    );
  }
);
