import axios, { AxiosResponse } from 'axios';

import {IPersonalCardFull, IMedia, ITooltip} from '../types/api';
import {LangType} from '../types/lang';
import {getLang} from './utils';
import {HistoryEditModel, CardEditModel} from '../types/models';

interface LoadCardResult {
  card?: IPersonalCardFull;
  message?: string;
  error?: string;
};

export const loadCard = async (token: string, langType: LangType): Promise<IPersonalCardFull> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<LoadCardResult>('/api/v1.0/user/card', {headers})
    .then((response: AxiosResponse<LoadCardResult>) => {
      if(response.data && response.data.card) return response.data.card;
      if(response.data && response.data.message)
        throw Error(`Load User error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load User error: ${response.data.error}`);
      throw Error(`Load User error: ${response.statusText}`);
    });
};

export const loadCardById = async (cardId: number, token: string, langType: LangType): Promise<IPersonalCardFull> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<LoadCardResult>(`/api/v1.0/cards/${cardId}`, {headers})
    .then((response: AxiosResponse<LoadCardResult>) => {
      if(response.data && response.data.card) return response.data.card;
      if(response.data && response.data.message)
        throw Error(`Load User error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load User error: ${response.data.error}`);
      throw Error(`Load User error: ${response.statusText}`);
    });
};

interface UpdateCardResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const updateCard = async (cardId: number, token: string, langType: LangType, data: any): Promise<boolean> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.put<UpdateCardResult>(`/api/v1.0/cards/${cardId}`, data, {headers})
    .then((response: AxiosResponse<UpdateCardResult>) => {
      if(response.data && response.data.success) return true;
      if(response.data && response.data.success === false) return false;
      if(response.data && response.data.message)
        throw Error(`Update Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Update Card error: ${response.data.error}`);
      throw Error(`Update Card error: ${response.statusText}`);
    });
};

interface UploadImageResult {
  success?: boolean;
  guid?: string;
  message?: string;
  error?: string;
};

export const uploadImage = async (token: string, langType: LangType, data: FormData): Promise<string> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'multipart/form-data'
  };
  return axios.post<UploadImageResult>(`/api/v1.0/cards/photo/upload`, data, {headers})
    .then((response: AxiosResponse<UploadImageResult>) => {
      if(response.data && response.data.guid) return response.data.guid;
      if(response.data && response.data.message)
        throw Error(`Upload Image Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Image Card error: ${response.data.error}`);
      throw Error(`Upload Image Card error: ${response.statusText}`);
    });
};

interface UploadFotoResult {
  media?: IMedia;
  message?: string;
  error?: string;
};

export const uploadMedia = async (token: string, cardId: number, langType: LangType, data: FormData): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'multipart/form-data'
  };
  return axios.post<UploadFotoResult>(`/api/v1.0/cards/${cardId}/media/upload`, data, {headers})
    .then((response: AxiosResponse<UploadFotoResult>) => {
      if(response.data && response.data.media) return;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface RemoveFotoResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const removeMedia = async (token: string, mediaId: number, langType: LangType): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.delete<RemoveFotoResult>(`/api/v1.0/cards/media/${mediaId}`, {headers})
    .then((response: AxiosResponse<RemoveFotoResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Remove Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Remove Foto Card error: ${response.data.error}`);
      throw Error(`Remove Foto Card error: ${response.statusText}`);
    });
};

interface AddHistoryResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const addHistory = async (token: string, langType: LangType, cardId: number, data: HistoryEditModel): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.post<AddHistoryResult>(`/api/v1.0/cards/${cardId}/history`, data, {headers})
    .then((response: AxiosResponse<AddHistoryResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface UpdateHistoryResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const updateHistory = async (token: string, langType: LangType, historyId: number, data: HistoryEditModel): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.put<UpdateHistoryResult>(`/api/v1.0/cards/history/${historyId}`, data, {headers})
    .then((response: AxiosResponse<UpdateHistoryResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface RemoveHistoryResult {
  success?: boolean;
  message?: string;
  error?: string;
}

export const removeHistory = async (token: string, historyId: number, langType: LangType): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.delete<RemoveHistoryResult>(`/api/v1.0/cards/history/${historyId}`, {headers})
    .then((response: AxiosResponse<RemoveHistoryResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Remove Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Remove Foto Card error: ${response.data.error}`);
      throw Error(`Remove Foto Card error: ${response.statusText}`);
    });
};

interface AddKidResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const addKid = async (token: string, langType: LangType, data: CardEditModel): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.post<AddKidResult>(`/api/v1.0/cards/son/add`, data, {headers})
    .then((response: AxiosResponse<AddKidResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface AddMotherResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const addMother = async (token: string, langType: LangType, data: CardEditModel): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.post<AddMotherResult>(`/api/v1.0/cards/mother/add`, data, {headers})
    .then((response: AxiosResponse<AddMotherResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface TooltipsResult {
  result?: ITooltip[];
  message?: string;
  error?: string;

};

export const tooltips = async (langType: LangType, cardId: number, query: string): Promise<ITooltip[]> => {
  const lang = getLang(langType);
  const headers = {
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.get<TooltipsResult>(`/api/v1.0/cards-tree/tooltip/${cardId}?q=${query}`, {headers})
    .then((response: AxiosResponse<TooltipsResult>) => {
      if(response.data && response.data.result) return response.data.result;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};
