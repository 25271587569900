import {observable, action} from 'mobx';

import {loadGenusCards} from '../services/gen.service';
import {ApiState} from './api.state';
import {IShortCardsList} from '../types/api';

export class GenState extends ApiState {
  @observable cardsList: IShortCardsList = {cards: []};

  @action toDefault() {
    super.toDefault();
    this.cardsList = {cards: []};
  }

  @action load(genusId: number): Promise<IShortCardsList> {
    this.toDefault();
    this.isLoading = true;
    const result = loadGenusCards(genusId);
    result
      .then(this.loadSuccess)
      .catch(this.loadFailed);
    return result;
  }

  @action.bound private loadSuccess(cardsList: IShortCardsList) {
    if(!this.isLoading) throw '';
    this.isLoading = false;
    this.cardsList = cardsList;
  }

  @action.bound private loadFailed(errorData: any) {
    this.isLoading = false;
    if(typeof errorData === 'string') this.error = errorData;
    else this.error = 'Load Cards List error';
  }
}
