import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import {Article} from '../pages/Article';
import {RodArticle} from '../pages/RodArticle';
import {Chat} from '../pages/Chat';
import {FAQ} from '../pages/FAQ';
import {Geo} from '../pages/Geo';
import {Main} from '../pages/Main';
import {News} from '../pages/News';
import {Rodnews} from '../pages/Rodnews';
import {History} from '../pages/History';
import {HistoryArticle} from '../pages/HistoryArticle';
import {Tree} from '../pages/Tree';
import {User} from '../pages/User';
import {UserMain} from '../pages/UserMain';
import {Error} from '../pages/Error';
import {Geneology} from '../pages/Geneology';

export class App extends Component<{}, {}> {

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/news" component={News} />
          <Route path="/rodnews" component={Rodnews} />
          <Route path="/history" component={History} />
          <Route path="/article/:articleId" component={Article} />
          <Route path="/rodarticle/:articleId" component={RodArticle} />
          <Route path="/historyarticle/:articleId" component={HistoryArticle} />
          <Route path="/chat/:userId?" component={Chat} />
          <Route path="/faq" component={FAQ} />
          <Route path="/geo/:cardId?" component={Geo} />
          <Route path="/usermain" component={UserMain} />
          <Route path="/user/:userId" component={User} />
          <Route path="/geneology/:cardId?" component={Geneology} />
          <Route path="/tree" component={Tree} />
          <Route path="/error" component={Error} />
          <Route path="/" component={Main} />
        </Switch>
      </Router>
    );
  }
};
