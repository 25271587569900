import React, {FC} from 'react';

import {IPersonalCardFull, IPersonLink} from '../types/api';
import {UserLink} from './UserLink';
import {useState} from './Store';

interface UserLinksProps {
  card: IPersonalCardFull;
  addMother: (card: IPersonalCardFull) => void;
  addKid: (card: IPersonalCardFull) => void;
  edit: (card: IPersonLink) => void;
};

export const UserLinks: FC<UserLinksProps> = (props: UserLinksProps) => {
  const
    appState = useState(),
    lang = appState.lang.lang.user.linksTab;

  const getRootFather = (): IPersonLink => {
    return {
      name: props.card.genus.name,
      allow_edit: false,
    } as IPersonLink;
  };
  
  const parents = () => {
    if(!props.card) return null;
    // if(!props.card.father && !props.card.mother) return null;
    if(!props.card.father) {

    }
    return (
      <div className="item-genus" key={"parents"}>
        {props.card && props.card.father ? (
          <UserLink info={props.card.father} role={lang.father} edit={() => props.edit(props.card.father)}></UserLink>
        ) : <UserLink info={getRootFather()} role={lang.father} edit={() => props.edit(props.card.father)}></UserLink>}
        {props.card && props.card.mother ? (
          <UserLink info={props.card.mother} role={lang.mother} edit={() => props.edit(props.card.mother)}></UserLink>
        ) : undefined}
      </div>
    );
  };

  const getKidRole = (person: IPersonLink): string => {
    return person.gender === 'M' ? lang.son : lang.dougter
  };

  const isAddMother = (): boolean => {
    return !props.card.mother;
  };

  const children = () => {
    if(!props.card) return null;
    const children = props.card.children;
    if(!children || children.length === 0) return null;
    const pairs: IPersonLink[][] = [];
    for(let index = 0; index < children.length; index += 2)
      pairs.push(children.slice(index, index + 2));
    return pairs.map((pair: IPersonLink[], index: number) => {
      return (
        <div className="item-genus" key={index}>
          {pair.map((kid: IPersonLink) => <UserLink info={kid} role={getKidRole(kid)} key={kid.id} edit={() => props.edit(kid)}></UserLink>)}
        </div> 
      )
    });
  };
  
  return (
    <div id="first">
      <div className="wrap user-contact-wrap">
        {parents()}
        {children()}
        <div className="user-contact-tools">
          {isAddMother() && props.card.allow_edit && <a className="btn-add-genus" onClick={() => props.addMother(props.card)}> + {lang.addMother}</a>}
          &nbsp;
          {props.card.allow_edit && <a className="btn-add-genus" onClick={() => props.addKid(props.card)}> + {lang.addKid}</a>}
        </div>
      </div>
    </div>
  );
};
