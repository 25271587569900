import React, {Component} from 'react';

import {IPersonLink} from '../types/api';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {Link} from 'react-router-dom';

interface UserLinkProps {
  info: IPersonLink;
  role: string;
  edit: () => void;
};

export class UserLink extends Component<UserLinkProps> {
  static contextType = StoreContext;

  get userPhoto(): string {
    if(this.props.info && this.props.info.photo_url) return this.props.info.photo_url;
    return '/images/no-image.png';
  }

  get userName(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    if(this.props.info && this.props.info.name) return this.props.info.name;
    return lang.noname;
  }

  get userSecondName(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    return lang.nofamily;
  }

  get userDateOfBirth(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    if(this.props.info && this.props.info.date_of_birth) return this.props.info.date_of_birth;
    return lang.nodob;
  }

  get userDateOfDeath(): string {
    return '';
  }

  render() {
    const edit = this.props.info.allow_edit ? (
      <div className="replace" onClick={this.props.edit}>
        <img src="/images/replace.png" alt="" />
      </div>
    ) : null;
    const photo = this.props.info.photo_url || '/images/no-image.png';
    const avatar = (this.props.info && this.props.info.id) ?
      (<Link to={`/user/${this.props.info.id}`}><img src={photo} alt="" /></Link>) :
      (<a><img src={photo} alt="" /></a>);
    return (
      <div className="info-genus">
        <p className="genus-tag">{this.props.role}</p>
        <div className="content-genus">
          <div className="photo-genus">
            {avatar}
          </div>
          <div className="text-genus">
            <p className="last-name-genus">{this.props.info.name}</p>
            <p className="first-name-genus">{this.props.info.middle_name} {this.props.info.surname}</p>
            <div className="user-date"> {this.props.info.date_of_birth} — {this.props.info.date_of_death}</div>
          </div>
          {edit}
        </div>
      </div>
    );
  }
};
