import React, {Component, ChangeEvent} from 'react';
import {observer} from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  MaterialUiPickersDate,
} from '@material-ui/pickers';

import {ModalBack} from './ModalBack';
import {CardEditModel} from '../types/models';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {uploadImage} from '../services/card.service';

interface CardEditorModalProps {
  model: CardEditModel;
  isKid: boolean;
  onClose: () => void;
  onSuccess: (card: CardEditModel) => void;
};

type CardEditorModalState = CardEditModel;

@observer
export class CardEditorModal extends Component<CardEditorModalProps, CardEditorModalState> {
  static contextType = StoreContext;
  
  constructor(props: CardEditorModalProps) {
    super(props);
    this.state = {
      gender: props.model.gender,
      surname: props.model.surname,
      name: props.model.name,
      middle_name: props.model.middle_name,
      date_of_birth: props.model.date_of_birth,
      date_of_death: props.model.date_of_death,
      phone: props.model.phone,
      address: props.model.address,
      email: props.model.email,
      photo: props.model.photo,
      photo_id: props.model.photo_id,
      photo_url: props.model.photo_url,
      personal_card_id: props.model.personal_card_id
    }
  }

  changeKid = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({gender: event.target.value});
  }

  changeSurname = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({surname: event.target.value});
  }

  changeName = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({name: event.target.value});
  }

  changeMiddle = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({middle_name: event.target.value});
  }

  changeDob = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    if(value) this.setState({date_of_birth: value});
  }

  changeDod = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    if(value) this.setState({date_of_birth: value});
  }

  changePhone = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({phone: event.target.value});
  }

  changeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({email: event.target.value});
  }

  changeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({address: event.target.value});
  }

  getCurrentPhotoUrl = (): string => {
    const url = this.props.model.photo_url || this.state.photo_url || '/images/no-image.png';
    return url;
  }

  success = () => {
    this.props.onSuccess({...this.state});
  }

  getExt(type: string): string {
    return type.split('/')[1];
  }

  changeFile = () => {
    const
      appState = this.context as AppState;
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    if(!fileUpload || !fileUpload.files || !fileUpload.files[0]) return;
    const file = fileUpload.files[0];
    const ext = this.getExt(file.type);
    const formData = new FormData();
    formData.append('photo', file);
    uploadImage(appState.auth.token, appState.lang.langType, formData).then((guid: string) => {
      const photo_url = `https://eshejire.kz/storage/photos/${guid}.${ext}`;
      this.setState({photo: guid, photo_url: photo_url});
    });
  }
  
  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.editor;
    return (
      <ModalBack onClick={() => this.props.onClose()}>
        <div className="card-editor-modal" onClick={(event: any) => event.stopPropagation()}>
          <div className="card-editor-container">
            <form>
              {this.props.isKid &&
                <div className="card-editor-row">
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="kid" name="kid" value={this.state.gender} onChange={this.changeKid} row>
                      <FormControlLabel
                        value="M"
                        control={<Radio color="primary" />}
                        label={lang.son}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="F"
                        control={<Radio color="primary" />}
                        label={lang.dougter}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              }
              <div className="card-editor-row">
                <FormControl className="card-editor-input">
                  <InputLabel htmlFor="component-simple">{lang.surname}</InputLabel>
                  <Input id="component-simple" value={this.state.surname} onChange={this.changeSurname} />
                </FormControl>
              </div>
              <div className="card-editor-row">
                <FormControl className="card-editor-input">
                  <InputLabel htmlFor="component-simple">{lang.name}</InputLabel>
                  <Input id="component-simple" value={this.state.name} onChange={this.changeName} />
                </FormControl>
              </div>
              <div className="card-editor-row">
                <FormControl className="card-editor-input">
                  <InputLabel htmlFor="component-simple">{lang.middleName}</InputLabel>
                  <Input id="component-simple" value={this.state.middle_name} onChange={this.changeMiddle} />
                </FormControl>
              </div>
              <div className="card-editor-date-container">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="flex-start" className="card-editor-date">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      autoOk={true}
                      openTo="year"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-of-birth"
                      label={lang.dob}
                      value={this.state.date_of_birth}
                      onChange={this.changeDob}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="flex-start" className="card-editor-date">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      autoOk={true}
                      openTo="year"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-of-death"
                      label={lang.dod}
                      value={this.state.date_of_death}
                      onChange={this.changeDod}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
              <div className="card-editor-row">
                <FormControl className="card-editor-input">
                  <InputLabel htmlFor="component-simple">{lang.phone}</InputLabel>
                  <Input id="component-simple" value={this.state.phone} onChange={this.changePhone} />
                </FormControl>
              </div>
              <div className="card-editor-row">
                <FormControl className="card-editor-input">
                  <InputLabel htmlFor="component-simple">{lang.phone}</InputLabel>
                  <Input id="component-simple" value={this.state.email} onChange={this.changeEmail} />
                </FormControl>
              </div>
              <div className="card-editor-row">
                <FormControl className="card-editor-input">
                  <InputLabel htmlFor="component-simple">{lang.address}</InputLabel>
                  <Input id="component-simple" value={this.state.address} onChange={this.changeAddress} />
                </FormControl>
              </div>
              <div className="card-editor-row">
                <div className="card-editor-load-image-btn">
                  <img src={this.getCurrentPhotoUrl()} alt="" />
                  {lang.image}
                  <input id="fileUpload" type="file" onChange={this.changeFile} />
                </div>
              </div>
            </form>
          </div>
          <div className="card-editor-save-button" onClick={this.success}>{lang.save}</div>
          <div className="modal-close-btn" onClick={this.props.onClose}></div>
        </div>
      </ModalBack>
    )
  }
};
