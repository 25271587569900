import {observable, action, computed} from 'mobx';

export class ApiState {
  @observable isLoading: boolean = false;
  @observable error: string = '';

  @computed get hasError(): boolean {
    return this.error !== '';
  }

  @action toDefault() {
    this.isLoading = false;
    this.error = '';
  }
}
