import React, {Fragment, PropsWithChildren, FC} from 'react';
import {Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';

import {Footer} from './Footer';
import {Header} from './Header';
import {useState} from './Store';

type LayoutInnerProps = PropsWithChildren<{}>;

export const LayoutInner: FC<LayoutInnerProps> = observer(
  (props: LayoutInnerProps) => {
    const appState = useState();
    if(!appState.auth.isAuth) {
      return (<Redirect to="/"></Redirect>);
    }
    const isTreePath: boolean = window.location.pathname.slice(0, 5) === '/tree';
    if(appState.user.user && !appState.user.user.genus_id && !isTreePath) {
      return (<Redirect to="/tree"></Redirect>);
    }
    return (
      <Fragment>
        <section className="main-article">
          <Header />
        </section>
        {props.children}
        <Footer />
      </Fragment>
    );
  }
);
