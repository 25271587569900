import {observable, action} from 'mobx';

import {loadUser} from '../services/user.service';
import {ApiState} from './api.state';
import {IUserExt} from '../types/api';
import {LangType} from '../types/lang';

export class UserState extends ApiState {
  @observable user: IUserExt | null = null;

  @action toDefault() {
    super.toDefault();
    this.user = null;
  }

  @action load(token: string, langType: LangType): Promise<IUserExt> {
    this.isLoading = true;
    this.user = null;
    this.error = '';
    const result = loadUser(token, langType);
    result
      .then(this.loadSuccess)
      .catch(this.loadFailed);
    return result;
  }

  @action.bound private loadSuccess(user: IUserExt) {
    if(!this.isLoading) throw '';
    this.isLoading = false;
    this.user = user;
  }

  @action.bound private loadFailed(errorData: any) {
    this.isLoading = false;
    if(typeof errorData === 'string') this.error = errorData;
    else this.error = 'User error';
  }
}
