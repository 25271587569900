import React, {Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';

import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {UserSettingsModal} from './UserSettingsModal';

interface MenuProps {};

interface MenuState {
  opened: boolean;
  settings: boolean;
  mobile: boolean;
};

@observer
export class Menu extends Component<MenuProps, MenuState> {
  static contextType = StoreContext;

  constructor(props: MenuProps) {
    super(props);
    this.state = {
      opened: false,
      settings: false,
      mobile: false
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(e: MouseEvent) {
    const img = document.getElementById('menu-user-image');
    const personal = document.getElementById('menu-use-item__personal');
    const news = document.getElementById('menu-use-item__news');
    const settings = document.getElementById('menu-use-item__settings');
    const exit = document.getElementById('menu-use-item__exit');
    const genus = document.getElementById('menu-use-item__genus');
    if(
      e.target !== img &&
      e.target !== personal &&
      e.target !== news &&
      e.target !== settings &&
      e.target !== exit &&
      e.target !== genus)
      this.setState({opened: false});
  }

  contextButtonClick() {
    this.setState({
      opened: !this.state.opened
    })
  }

  exit() {
    const appState = this.context as AppState;
    appState.signout();
  }

  openSettings = () => {
    this.setState({settings: true});
  }

  closeSettings = () => {
    this.setState({settings: false});
  }

  openMobile = () => {
    this.setState({mobile: true});
  }

  closeMobile = () => {
    this.setState({mobile: false});
  }

  toggleMobile = () => {
    this.setState({mobile: !this.state.mobile});
  }

  render() {
    const appState = this.context as AppState;
    const {menu} = appState.lang.lang;
    const card_id =
      appState.user.user &&
      appState.user.user.has_card &&
      appState.user.user.personal_card ? appState.user.user.personal_card.id : 0;
    const contextMenu = this.state.opened ? (
      <div className="menu-user__context">
        <Link id="menu-use-item__personal" to={`/user/${card_id}`}>{menu.context.personal}</Link>
        <Link id="menu-use-item__news" to={`/news`}>{menu.context.news}</Link>
        <Link id="menu-use-item__genus" to="/tree">{menu.context.tree}</Link>
        <a id="menu-use-item__settings" onClick={this.openSettings}>{menu.context.settings}</a>
        <a id="menu-use-item__exit" onClick={this.exit.bind(this)}>{menu.context.exit}</a>
      </div>
    ) : (null);

    const mobileMenuBtnClasses = `menu-btn menu-btn-article ${this.state.mobile ? 'menu-btn--on' : ''}`;
    const mobilMenuClasses = `mobil-menu-wrap ${this.state.mobile ? 'mobil-menu-open' : ''}`;
    
    return (
      <Fragment>
        <div className="main-menu">
          <div className="menu menu-article">
            <Link to="/rodnews">{menu.news}</Link>
            <Link to="/history">{menu.history}</Link>
            <Link to="/geneology">{menu.geneology}</Link>
            <Link to="/geo">{menu.geo}</Link>
            <Link to="/chat">{menu.chat}</Link>
            <Link to="/faq">{menu.faq}</Link>
          </div>
          <nav className={mobileMenuBtnClasses} onClick={this.toggleMobile}>
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </nav>
          <div className="menu-user" onClick={this.contextButtonClick.bind(this)}>
            <img id="menu-user-image" src="/images/user.webp" alt="" />
            {contextMenu}
          </div>
        </div>
        <div className="mobil-menu">
          <div className={mobilMenuClasses}>
            <Link to="/rodnews">{menu.news}</Link>
            <Link to="/history">{menu.history}</Link>
            <Link to="/geneology">{menu.geneology}</Link>
            <Link to="/geo">{menu.geo}</Link>
            <Link to="/chat">{menu.chat}</Link>
            <Link to="/faq">{menu.faq}</Link>
          </div>
        </div>
        {this.state.settings && <UserSettingsModal onClose={this.closeSettings}></UserSettingsModal>}
      </Fragment>
    );
  }
};
