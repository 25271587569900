import React, {Component} from 'react';

import {IPersonalCardFull, IMedia} from '../types/api';
import {uploadMedia, removeMedia} from '../services/card.service';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {ModalBack} from './ModalBack';

interface UserVideoProps {
  card: IPersonalCardFull | null;
  onModerate: () => void;
};

interface UserVideoState {
  video: IMedia | null;
  remove: IMedia | null;
  isRemove: boolean;
};

export class UserVideo extends Component<UserVideoProps, UserVideoState> {
  static contextType = StoreContext;

  constructor(props: UserVideoProps) {
    super(props);
    this.state = {video: null, remove: null, isRemove: false};
  }

  removeVideo = (video: IMedia) => {
    this.setState({remove: video, isRemove: true});
  }

  closeRemoveModal = () => {
    this.setState({remove: null, isRemove: false});
  }

  successRemoveModal = () => {
    const remove: IMedia | null = this.state.remove;
    if(!remove) return;
    const
      appState = this.context as AppState,
      langType = appState.lang.langType;
    removeMedia(appState.auth.token, remove.id, langType).then(() => {
      this.setState({remove: null, isRemove: false});
      this.props.onModerate();
    });
  }

  openSlide = (video: IMedia) => {
    this.setState({video});
  }

  closeSlide = () => {
    this.setState({video: null});
  }

  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.user.videoTab;
  
    const uploadVideo = () => {
      if(!this.props.card) return;
      const fileUpload = document.getElementById('videoUpload') as HTMLInputElement;
      if(!fileUpload || !fileUpload.files || !fileUpload.files[0]) return;
      const file = fileUpload.files[0];
      const formData = new FormData();
      formData.append('media', file);
      uploadMedia(appState.auth.token, this.props.card.id, appState.lang.langType, formData).then(this.props.onModerate);
    };

    const exists = this.props.card && this.props.card.videos && this.props.card.videos.length > 0 ? true : false;
  
    const videos = () => {
      if(!this.props.card || !this.props.card.videos || this.props.card.videos.length == 0) return null;
      const edit = this.props.card && this.props.card.allow_edit;
      return this.props.card.videos.map((video: IMedia) => {
        return (
          <div className="photo-bank-wrap" key={video.id} onClick={() => this.openSlide(video)}>
            <img src={video.preview_url} alt="" />
            {edit && <div className="photo-bank-wrap-delete" onClick={(event) => {event.stopPropagation(); this.removeVideo(video);}}></div>}
          </div>
        );
      });
    };
  
    const addVideo = () => {
      if(!this.props.card || !this.props.card.allow_edit) return null;
      return (
        <div className="user-contact-tools">
          <a className="btn-add-genus">
            + {lang.add}
            <input id="videoUpload" type="file" onChange={uploadVideo} />
          </a>
        </div>
      );
    };

    const slider = () => {
      if(!this.state.video) return null;
      return (
        <ModalBack onClick={this.closeSlide}>
          <div className="photo-slide-close-btn" onClick={this.closeSlide}></div>
          <div className="video-slide-wrap">
            <video className="video-slide" controls onClick={(event) => event.stopPropagation()}>
              <source src={this.state.video.file_url} />
            </video>
          </div>
        </ModalBack>
      );
    };

    const removeModal = () => {
      if(!this.state.isRemove) return null;
      return (
        <ModalBack onClick={this.closeRemoveModal}>
          <div className="add-card-modal" onClick={(event: any) => event.stopPropagation()}>
            <div className="modal-close-btn" onClick={this.closeRemoveModal}></div>
            <div className="add-card-title">{lang.remove}</div>
            <div className="add-card-ok-button" onClick={this.successRemoveModal}>{lang.removeTxt}</div>
          </div>
        </ModalBack>
      );
    };
    
    return (
      <div id="forth">
        <div className="wrap user-contact-wrap">
          <div className="photo-bank">
            {!exists && <h1>{lang.novideo}</h1>}
            {videos()}
          </div>
          {addVideo()}
        </div>
        {removeModal()}
        {slider()}
      </div>
    );
  }
};
