import React, {Component, FormEvent} from 'react';
import {observer} from 'mobx-react';

import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {ModalBack} from './ModalBack';
import {RecoverPassword} from '../types/models';
import {getCode, recoverPassword} from '../services/auth.service';

interface RecoverPasswordModalProps {
  onClose: () => void;
  onSuccess: () => void;
};

interface RecoverPasswordModalState extends RecoverPassword {
  isCodeSend: boolean;
  isNumberValid: boolean;
  isCodeValid: boolean;
  isPasswordValid: boolean;
};

@observer
export class RecoverPasswordModal extends Component<RecoverPasswordModalProps, RecoverPasswordModalState> {
  static contextType = StoreContext;

  constructor(props: RecoverPasswordModalProps) {
    super(props);
    this.state = {
      phone: '',
      code: '',
      password: '',
      isCodeSend: false,
      isNumberValid: true,
      isCodeValid: true,
      isPasswordValid: true
    };
  }

  changePhone(event: FormEvent<HTMLInputElement>) {
    this.setState({phone: event.currentTarget.value, isNumberValid: true, isCodeValid: true, isPasswordValid: true});
  }

  changeCode(event: FormEvent<HTMLInputElement>) {
    this.setState({code: event.currentTarget.value, isNumberValid: true, isCodeValid: true, isPasswordValid: true});
  }

  changePassword(event: FormEvent<HTMLInputElement>) {
    this.setState({password: event.currentTarget.value, isNumberValid: true, isCodeValid: true, isPasswordValid: true});
  }

  checkPhone(): boolean {
    if(this.state.phone.length !== 12 && this.state.phone[0] !== '+') {
      this.setState({isNumberValid: false});
      return false;
    }
    return true;
  }

  checkCode() {
    if(this.state.code.length !== 4) {
      this.setState({isCodeSend: false});
      return false;
    }
    return true;
  }

  checkPassword() {
    if(this.state.password.length < 4) {
      this.setState({isPasswordValid: false});
      return false;
    }
    return true;
  }

  sendCode() {
    if(this.checkPhone()) {
      const
        appState = this.context as AppState,
        langType = appState.lang.langType;
      getCode(langType, {phone: this.state.phone}).then(() => {
        this.setState({isCodeSend: true});
      });
    }
  }

  recover() {
    if(this.checkPhone() && this.checkCode() && this.checkPassword()) {
      const
        appState = this.context as AppState,
        langType = appState.lang.langType;
      recoverPassword(langType, {phone: this.state.phone, code: this.state.code, password: this.state.password}).then(() => {
        this.props.onSuccess();
      });
    }
  }
  
  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.recoverModal;
    const button = this.state.isCodeSend ? (
      <input className="send-code-btn" type="button" value={lang.sendCode} onClick={this.recover.bind(this)} />
    ) : (
      <input className="send-code-btn" type="button" value={lang.sendCode} onClick={this.sendCode.bind(this)} />
    );
    return (
      <ModalBack onClick={this.props.onClose}>
        <div className="recover-password-modal" onClick={(event: any) => event.stopPropagation()}>
          <div className="form-tab">
            <div className="filds">
              {this.state.isNumberValid ? (
                <p>{lang.phone}</p>
              ) : (
                <p style={{color: 'red'}}>{lang.phoneError}</p>
              )}
              <input value={this.state.phone} onChange={this.changePhone.bind(this)} type="text" placeholder="+7( ____ ) _____________" />
            </div>
            <div className="filds">
              {this.state.isCodeValid ? (
                <p>{lang.code}</p>
              ) : (
                <p style={{color: 'red'}}>{lang.codeError}</p>
              )}
              <input value={this.state.code} disabled={!this.state.isCodeSend} onChange={this.changeCode.bind(this)} type="text" />
            </div>
            <div className="filds">
              {this.state.isCodeValid ? (
                <p>{lang.password}</p>
              ) : (
                <p style={{color: 'red'}}>{lang.passwordError}</p>
              )}
              <input type="password" disabled={!this.state.isCodeSend} value={this.state.password} onChange={this.changePassword.bind(this)} />
            </div>
            {button}
          </div>
          <div className="modal-close-btn" onClick={this.props.onClose}></div>
        </div>
      </ModalBack>
    )
  }
}
