import React, { Component, ChangeEvent, KeyboardEvent } from 'react';
import {observer} from 'mobx-react';

import {LayoutInner} from '../components/LayoutInner';
import {Menu} from '../components/Menu';
import {getChatList, getChatMessages, sendToChat, uploadMedia, getChatByUserId} from '../services/chat.service';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {ICahtLastMsg, IChatMsgs, IChatMessage, IChatMedia} from '../types/api';
import {ChatItem} from '../components/ChatItem';
import {ChatMessages} from '../types/models';
import {TextMsg} from '../components/TextMsg';
import {isSameDay, isToday, getTime, getMonthDay, isYesterday} from '../services/utils';

interface ChatDay {
  day: Date;
  messages: IChatMessage[];
};

interface ChatProps {};

interface ChatState {
  isLoadingChats: boolean;
  isLoadingMessages: boolean;
  chats: ICahtLastMsg[];
  chat: ICahtLastMsg | null;
  messages: IChatMessage[];
  text: string;
  search: string;
};

@observer
export class Chat extends Component<ChatProps, ChatState> {
  static contextType = StoreContext;
  
  constructor(props: ChatProps) {
    super(props);
    this.state = {
      isLoadingChats: true,
      isLoadingMessages: false,
      chats: [],
      chat: null,
      messages: [],
      text: '',
      search: ''
    };
  }

  loadChats() {
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    getChatList(token, langType).then((chats) => {
      this.setState({chats}, () => {
        const userId = this.getUserId();
        if(userId) {
          getChatByUserId(token, langType, userId).then((userChat: ICahtLastMsg) => {
            const existChat = chats.find((chat: ICahtLastMsg) => chat.id === userChat.id);
            if(existChat) {
              this.selectChat(existChat);
              this.setState({isLoadingChats: false});
            } else {
              chats.push(userChat);
              this.setState({isLoadingChats: false, chats}, () => {
                this.selectChat(userChat);
              });
            }
          });
        } else {
          this.setState({isLoadingChats: false});
        }
      });
    });
  }

  componentDidMount() {
    this.loadChats();
  }

  selectChat = (chat: ICahtLastMsg) => {
    if(this.state.chat && this.state.chat.id === chat.id) return;
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    this.setState({isLoadingMessages: true, chat, messages: []}, () => {
      const args: ChatMessages = {
        direction: 'desc',
        from_message_id: null,
        search: null,
        limit: 25
      };
      getChatMessages(token, langType, chat.id, args)
        .then((chatMsgs: IChatMsgs) => this.setState({isLoadingMessages: false, messages: chatMsgs.messages}));
    });
  }

  isSelected(chat: ICahtLastMsg): boolean {
    if(!this.state.chat) return false;
    return this.state.chat.id === chat.id;
  }

  textChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({text: event.target.value});
  }

  sendMessage = () => {
    const text = this.state.text;
    const selected = this.state.chat;
    if(!text || !selected) return;
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    sendToChat(token, langType, selected.id, {type: 'text', message: text}).then(() => {
      const args: ChatMessages = {
        direction: 'desc',
        from_message_id: null,
        search: null,
        limit: 25
      };
      getChatMessages(token, langType, selected.id, args)
        .then((chatMsgs: IChatMsgs) => this.setState({isLoadingMessages: false, messages: chatMsgs.messages}));
    });
    this.setState({isLoadingMessages: true, text: ''});
  }

  uploadMedia = () => {
    const selected = this.state.chat;
    if(!selected) return;
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    const fileUpload = document.getElementById('mediaUpload') as HTMLInputElement;
    if(!fileUpload || !fileUpload.files || !fileUpload.files[0]) return;
    const file = fileUpload.files[0];
    const formData = new FormData();
    formData.append('media', file);
    uploadMedia(token, langType, formData).then((result: IChatMedia) => {
      sendToChat(token, langType, selected.id, {type: result.type, media_id: result.id}).then(() => {
        const args: ChatMessages = {
          direction: 'desc',
          from_message_id: null,
          search: null,
          limit: 25
        };
        getChatMessages(token, langType, selected.id, args)
          .then((chatMsgs: IChatMsgs) => this.setState({isLoadingMessages: false, messages: chatMsgs.messages}));
      });
    });
    this.setState({isLoadingMessages: true});
  }

  searchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({search: value});
    if(!value) {
      const selected = this.state.chat;
      if(!selected) return;
      const
        appState = this.context as AppState,
        token = appState.auth.token,
        langType = appState.lang.langType;
      const args: ChatMessages = {
        direction: 'desc',
        from_message_id: null,
        search: null,
        limit: 25
      };
      getChatMessages(token, langType, selected.id, args)
        .then((chatMsgs: IChatMsgs) => this.setState({isLoadingMessages: false, messages: chatMsgs.messages}));
      this.setState({isLoadingMessages: true});
    }
  }

  searchEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if(event.key !== 'Enter') return;
    this.searchClick();
  }

  searchClick = () => {
    const selected = this.state.chat;
    const search = this.state.search;
    if(!selected || !search) return;
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    const args: ChatMessages = {
      direction: 'desc',
      from_message_id: null,
      search: search,
      limit: 25
    };
    getChatMessages(token, langType, selected.id, args)
      .then((chatMsgs: IChatMsgs) => this.setState({isLoadingMessages: false, messages: chatMsgs.messages}));
    this.setState({isLoadingMessages: true});
  }

  getUserId(): number | null {
    let userId: number | null = null;
    const anyProps = this.props as any;
    if(
      anyProps.match &&
      anyProps.match.params &&
      anyProps.match.params.userId) {
        userId = parseInt(anyProps.match.params.userId);
      if(isNaN(userId)) userId = null;
    }
    return userId;
  }

  getDateTime = (date: Date): string => {
    if(isToday(date)) return 'Сегодня';
    if(isYesterday(date)) return 'Вчера';
    return getMonthDay(date);
  };

  getMessagesByDay(userId: number) {
    const result: ChatDay[] = [];
    const messages = this.state.messages;
    messages.forEach((message: IChatMessage) => {
      const messageDay = new Date(message.created_at * 1000);
      const existDay = result.find((chatDay: ChatDay) => isSameDay(chatDay.day, messageDay));
      if(existDay) existDay.messages.push(message);
      else {
        const newDay: ChatDay = {day: messageDay, messages: []};
        newDay.messages.push(message);
        result.push(newDay);
      }
    });
    return (
      <>
      {result.map((chatDay: ChatDay) => (
        <div key={chatDay.day.getTime()}>
          <p className="massage-date">{this.getDateTime(chatDay.day)}</p>
          {chatDay.messages.map((message: IChatMessage) => (
            <TextMsg message={message} userId={userId} key={message.id}></TextMsg>
          ))}
        </div>
      ))}
      </>
    );
  }

  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.chat;
    const user = appState.user.user;
    const chats = this.state.chats.map((chat: ICahtLastMsg) => <ChatItem chat={chat} key={chat.id} onSelect={this.selectChat} isSelected={this.isSelected(chat)} ></ChatItem>);
    const hasSelected = !!this.state.chat;
    return (
      <LayoutInner>
        <section className="content-article">
          <div className="wrap">
            <Menu />
            <div className="main-article-content">
              <h1 className="news-title">{lang.title}</h1>
              <div className="chat-window">
                <div className="chat-header">
                    <div className="chat-search">
                      <div className="search">
                        <input type="search" value={this.state.search} className="input" onKeyUp={this.searchEnter} onChange={this.searchChange} />
                        <input type="button" name="" value="" className="submit" onClick={this.searchClick} />
                      </div>
                    </div>
                    {/* <div className="chat-user-info">
                      <div className="btn-chat-mob">
                        <img src="images/pagination-arrow-left.webp" alt="" />
                      </div>
                      <img src="images/chat-man.png" alt="" />
                      <div className="chat-user-online">
                        <p className="chat-user-name">Арман</p>
                        <p className="user-online">был(а) сегодня в 16:45</p>
                      </div>
                    </div> */}
                </div>
                <div className="chat-body">
                    <div className="chat-contact hidden-menu">
                      {this.state.isLoadingChats && <div className="spinner2"></div>}
                      {chats}
                    </div>
                    <div className="chat-massages">
                      <div className="chat-messages-list">
                        {this.state.isLoadingMessages && <div className="spinner2"></div>}
                        {user && this.getMessagesByDay(user.id)}
                      </div>
                      {hasSelected &&
                        <div className="chat-footer">
                          <div className="form-add-file">
                            <div className="add-file"></div>
                            <input id="mediaUpload" type="file" onChange={this.uploadMedia} />
                          </div>
                          <div className="search chat-form">
                            <textarea placeholder="Введите сообщение" className="input" rows={1} value={this.state.text} onChange={this.textChange}></textarea>
                            <input type="button" className="submit telegram" onClick={this.sendMessage} />
                          </div>
                        </div>
                      }
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutInner>
    );
  }
};
