import {observable, action, computed} from 'mobx';

import {ApiState} from './api.state';
import {registration} from '../services/auth.service';
import {IAuth} from '../types/models';

export class RegistrationState extends ApiState {
  @observable token: string = '';
  @computed get isRegistrate(): boolean {
    return this.token !== '';
  };

  @action toDefault() {
    super.toDefault();
    this.token = '';
  }
  
  @action load(auth: IAuth): Promise<string> {
    this.isLoading = true;
    this.error = '';
    this.token = '';
    const result = registration(auth);
    result
      .then(this.loadSuccess)
      .catch(this.loadFailed);
    return result;
  }
  
  @action.bound private loadSuccess(token: string) {
    if(!this.isLoading) throw '';
    this.isLoading = false;
    this.token = token;
  }
  
  @action.bound private loadFailed(errorData: any) {
    this.isLoading = false;
    if(typeof errorData === 'string') this.error = errorData;
    else this.error = 'Registration error';
  }
}
