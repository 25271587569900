import React, {FC} from 'react';
import {withRouter} from 'react-router-dom';
import {observer} from 'mobx-react';

import {LangType} from '../types/lang';
import {useState} from './Store';

const LogoButton = withRouter(({history}) => (
  <div className="logo" onClick={() => history.push('/')}>
    <div className="logo-simbol">
      <img src="/images/logo-simbol-2.png" alt="" />
    </div>
    <div className="name_logo">
      <img src="/images/logo-name.png" alt="" />
    </div>
  </div>
));

interface HeaderProps {};

export const Header: FC<HeaderProps> = observer(
  () => {
    const appState = useState();
    const
      {lang} = appState,
      {langType} = lang;
    return (
      <div className="nav">
        <div className="wrap-nav">
          <LogoButton />
          <div className="lang">
            <a target="_blank" href="mailto:info@eshejire.kz"><img src="/images/icon-mail.png" alt="" /></a>
            <a target="_blank" href="https://wa.me/77774846323"><img src="/images/icon-phone.png" alt="" /></a>
            <div className="language">
              <a className={langType === LangType.RU ? 'active' : ''} href={undefined} onClick={() => lang.setRu()}>RU</a>&nbsp;<a href={undefined}>/</a>&nbsp;
              <a className={langType === LangType.KZ ? 'active' : ''} href={undefined} onClick={() => lang.setKz()}>KAZ</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
