import React, {Component} from 'react';

import {IChatMessage} from '../types/api';
import {isToday, getDate, getTime, getMonthDay} from '../services/utils';
import {ModalBack} from './ModalBack';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';

interface TextMsgProps {
  message: IChatMessage;
  userId: number;
};

interface TextMsgState {
  isOpenImage: boolean;
  isOpenVideo: boolean;
};

export class TextMsg extends Component<TextMsgProps, TextMsgState> {
  static contextType = StoreContext;

  constructor(props: TextMsgProps) {
    super(props);
    this.state = {
      isOpenImage: false,
      isOpenVideo: false
    };
  }

  isFromMe(): boolean {
    return this.props.message.from_user.id === this.props.userId;
  };

  getDateTime = (date: Date): string => {
    return isToday(date) ? getTime(date) : `${getMonthDay(date)} ${getTime(date)}`;
  };

  getContent() {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.textMsg;
    const message = this.props.message;
    const date = new Date(message.created_at * 1000);
    if(message.type === 'text') {
      if(this.isFromMe()) {
        return (
          <div className="chat-message-container">
            <div className="wrap-to-whom">
              <div className="to-whom">{message.message}</div>
              <p className="look see-massage">{this.getDateTime(date)}</p>
              {message.status === 'read' && <p className="look see-massage">{lang.read}</p>}
            </div>
          </div>
        );
      } else {
        return (
          <div className="chat-message-container">
            <div className="wrap-from-whom">
              <div className="from-whom">{message.message}</div>
              <p className="look see-massage">{this.getDateTime(date)}</p>
            </div>
          </div>
        );
      }
    }
    if(message.type === 'photo' && message.media) {
      if(this.isFromMe()) {
        return (
          <div className="chat-message-container">
            <div className="wrap-to-whom">
              <div className="to-whom image-message">
                <img src={message.media.file_url} alt="" onClick={this.openImage}/>
              </div>
              <p className="look see-massage">{this.getDateTime(date)}</p>
              {message.status === 'read' && <p className="look see-massage">{lang.read}</p>}
            </div>
          </div>
        );
      } else {
        return (
          <div className="chat-message-container">
            <div className="wrap-from-whom">
              <div className="from-whom image-message">
                <img src={message.media.file_url} alt="" onClick={this.openImage}/>
              </div>
              <p className="look see-massage">{this.getDateTime(date)}</p>
            </div>
          </div>
        );
      }
    }
    if(message.type === 'video' && message.media) {
      if(this.isFromMe()) {
        return (
          <div className="chat-message-container">
            <div className="wrap-to-whom">
              <div className="to-whom image-message">
                <img src={message.media.preview_url} alt="" onClick={this.openVideo}/>
              </div>
              <p className="look see-massage">{this.getDateTime(date)}</p>
              {message.status === 'read' && <p className="look see-massage">{lang.read}</p>}
            </div>
          </div>
        );
      } else {
        return (
          <div className="chat-message-container">
            <div className="wrap-from-whom">
              <div className="from-whom image-message">
                <img src={message.media.preview_url} alt="" onClick={this.openVideo}/>
              </div>
              <p className="look see-massage">{this.getDateTime(date)}</p>
            </div>
          </div>
        );
      }
    }
    return (<></>);
  }

  openImage = () => {
    this.setState({isOpenImage: true});
  }

  openVideo = () => {
    this.setState({isOpenVideo: true});
  }

  closeImage = () => {
    this.setState({isOpenImage: false});
  }

  closeVideo = () => {
    this.setState({isOpenVideo: false});
  }

  getVideoModal() {
    const message = this.props.message;
    const media = message.media;
    if(!this.state.isOpenVideo || !media) return null;
    const splitUrl = media.preview_url.split('/');
    const hash = splitUrl[splitUrl.length - 1].replace('_preview.png', '');
    const splitName = media.original_name.split('.');
    const ext = splitName[splitName.length - 1];
    const fileUrl = `https://eshejire.kz/storage/media/${hash}.${ext}`;
    console.log(fileUrl);
    return (
      <ModalBack onClick={this.closeVideo}>
        <div className="photo-slide-close-btn" onClick={this.closeVideo}></div>
        <div className="video-slide-wrap">
          <video className="video-slide" controls onClick={(event) => event.stopPropagation()}>
            <source src={fileUrl} />
          </video>
        </div>
      </ModalBack>
    );
  }

  getImageModal() {
    const message = this.props.message;
    const media = message.media;
    if(!this.state.isOpenImage || !media) return null;
    return (
      <ModalBack onClick={this.closeImage}>
        <div className="photo-slide-close-btn" onClick={this.closeImage}></div>
        <div className="photo-slide" style={{backgroundImage: `url(${media.file_url})`}}></div>
      </ModalBack>
    );
  }
  
  render() {
    const content = this.getContent();
    const imageModal = this.getImageModal();
    const videoModal = this.getVideoModal();
    return (
      <>
        {content}
        {imageModal}
        {videoModal}
      </>
    );
  }
}
