import {LangType} from '../types/lang';

export const getLang = (langType: LangType): string => {
  switch(langType) {
    case LangType.RU: return 'ru';
    case LangType.KZ: return 'kk';
  };
};

const getZeroNum = (num: number): string => {
  return num > 9 ? num.toString() : `0${num}`;
}

export const getDate = (date: Date): string => {
  const day = getZeroNum(date.getDate());
  const month = getZeroNum(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getMonthDay = (date: Date): string => {
  const day = getZeroNum(date.getDate());
  const month = getZeroNum(date.getMonth() + 1);
  return `${month}-${day}`;
}; 

export const getTime = (date: Date): string => {
  const minutes = getZeroNum(date.getMinutes());
  const hours = getZeroNum(date.getHours());
  return `${hours}:${minutes}`;
};

export const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const isYesterday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() == (today.getDate() - 1) &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const isSameDay = (dayOne: Date, dayTwo: Date): boolean => {
  return (
    dayOne.getDate() === dayTwo.getDate() &&
    dayOne.getMonth() === dayTwo.getMonth() &&
    dayOne.getFullYear() === dayTwo.getFullYear()
  );
}
