import {computed, autorun} from 'mobx';

import {LangState} from './lang.state';
import {AuthState} from './auth.state';
import {UserState} from './user.state';
import {CardState} from './card.state';
import {NewsState} from './news.state';
import {RodnewsState} from './rodnews.state';
import {HistoryState} from './history.state';
import {TreeState} from './tree.state';
import {RegistrationState} from './registration.state';
import {IAuth} from '../types/models';
import {LangType} from '../types/lang';
import {saveToken, loadToken, clearToken, saveLang, loadLang} from '../services/local.service';
import {IUserExt, IPersonalCardFull} from '../types/api';
import {GenState} from './gen.state';
import {updateGeo} from '../services/geo.service';

export class AppState {
  lang: LangState = new LangState();
  auth: AuthState = new AuthState();
  user: UserState = new UserState();
  card: CardState = new CardState();
  news: NewsState = new NewsState();
  rodnews: RodnewsState = new RodnewsState();
  history: HistoryState = new HistoryState();
  tree: TreeState = new TreeState();
  gen: GenState = new GenState();
  registration: RegistrationState = new RegistrationState();

  signin(auth: IAuth): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.auth.load(auth)
        .then((token: string) => this.loadByToken(token).then(resolve).catch(reject))
        .catch(reject);
    });
  }

  updateGeo(token: string, langType: LangType) {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        updateGeo(token, langType, position.coords);
      });
      
    }
  }

  loadByToken(token: string): Promise<void> {
    const langType = this.lang.langType;
    const newsPromise = this.news.load(token, langType);
    // const rodnewsPromise = this.rodnews.load(token, langType);
    // const historyPromise = this.history.load(token, langType);
    const treePromise = this.tree.loadGenusList(langType);
    const promise = new Promise<void>((resolve, reject) => {
      this.user.load(token, langType).then((user: IUserExt) => {
        const promises: Promise<void>[] = [];
        if(user.genus_id) {
          promises.push(this.gen.load(user.genus_id).then());
          promises.push(this.rodnews.load(token, langType).then());
          promises.push(this.history.load(token, langType).then());
        }
        if(user.has_card) promises.push(this.card.load(token, langType).then((card: IPersonalCardFull) => {
          if(card.allow_geo) this.updateGeo(token, langType);
        }).then());
        Promise.all(promises).then(() => resolve()).catch(reject);
      }).catch(reject);
    });
    return Promise.all([newsPromise, treePromise, promise]).then();
  }

  setLang(langType: LangType) {
    if(langType === LangType.RU) this.lang.setRu();
    if(langType === LangType.KZ) this.lang.setKz();
  }

  signout() {
    this.auth.toDefault();
    this.user.toDefault();
    this.card.toDefault();
    this.news.toDefault();
    this.rodnews.toDefault();
    this.history.toDefault();
  }

  @computed get hasError(): boolean {
    return this.user.hasError || this.news.hasError || this.rodnews.hasError || this.history.hasError;
  }
}

export const createState = (): AppState => {
  const appState = new AppState();
  
  (() => {
    const langType: LangType = loadLang();
    appState.setLang(langType);
    const token = loadToken();
    if(token && token !== '') {
      appState.auth.token = token;
      appState.loadByToken(token);
    }
  })();
  
  autorun(() => {
    const
      oldLangType = loadLang(),
      newLangType = appState.lang.langType;

    if(oldLangType !== newLangType) {
      const token = appState.auth.token;
      appState.news.load(token, newLangType);
      appState.rodnews.load(token, newLangType);
      appState.history.load(token, newLangType);
      saveLang(newLangType);
    }
    if(appState.hasError) {
      clearToken();
      setTimeout(() => {
        appState.signout();
      }, 0);
    } else if(appState.auth.isAuth) saveToken(appState.auth.token);
    else clearToken();
  });

  return appState;
};