export enum LangType {
  RU = 'RU',
  KZ = 'KZ'
};

export interface FaqItem {
  title: string;
  text: string;
};

export interface Lang {
  signin: {
    phone: string;
    password: string;
    forget: string;
    enter: string;
    error: string;
  };
  registration: {
    phone: string;
    password: string;
    registrate: string;
    existError: string;
    lengthError: string;
    agreementOne: string;
    agreementTwo: string;
  };
  auth: {
    sigin: string;
    registration: string;
  };
  main: {
    tree: string;
    text: string;
    download: string;
  };
  menu: {
    news: string;
    history: string;
    tree: string;
    geo: string;
    chat: string;
    faq: string;
    geneology: string;
    context: {
      personal: string;
      tree: string;
      exit: string;
      settings: string;
      news: string;
    }
  };
  footer: {
    write: string;
  };
  news: {
    title: string;
  };
  newsitem: {
    full: string;
  };
  article: {
    allnews: string;
    share: string;
    allhistory: string;
  };
  user: {
    nocard: string;
    write: string;
    onmap: string;
    tree: string;
    links: string;
    history: string;
    photo: string;
    video: string;
    contacts: string;
    contactsTab: {
      fio: string;
      dob: string;
      dod: string;
      phone: string;
      email: string;
      address: string;
      page: string;
    };
    noname: string;
    nofamily: string;
    nodob: string;
    nophone: string;
    noemail: string;
    noaddress: string;
    linksTab: {
      father: string;
      mother: string;
      son: string;
      dougter: string;
      addMother: string;
      addKid: string;
    };
    historyTab: {
      nohistory: string;
      add: string;
      titleRu: string;
      textRu: string;
      titleKz: string;
      textKz: string;
      remove: string;
      save: string;
    };
    fotoTab: {
      add: string;
      remove: string;
      removeTxt: string;
      nofoto: string;
    };
    videoTab: {
      add: string;
      remove: string;
      removeTxt: string;
      novideo: string;
    };
  };
  tree: {
    source: string;
    ok: string;
    choose: string;
    list: string;
    search: string;
    cancel: string;
    emptyMsg1: string;
    emptyMsg2: string;
    demo: string;
  };
  geneology: {
    add: string;
    tree: string;
    list: string;
    search: string;
  };
  editor: {
    son: string;
    dougter: string;
    surname: string;
    name: string;
    middleName: string;
    dob: string;
    dod: string;
    phone: string;
    email: string;
    address: string;
    image: string;
    save: string;
  };
  addCardModal: {
    msg: string;
    add: string;
  };
  genus: {
    inwork: string;
  };
  moderationModal: {
    request: string;
    close: string;
  };
  recoverModal: {
    sendCode: string;
    phone: string;
    phoneError: string;
    code: string;
    codeError: string;
    password: string;
    passwordError: string;
  };
  textMsg: {
    read: string;
  };
  settingsModal: {
    msg: string;
    geo: string;
    fem: string;
    save: string;
  };
  chat: {
    title: string;
  };
  geo: {
    title: string;
  };
  history: {
    title: string;
  };
  agreement: {
    close: string;
  };
  faqItems: FaqItem[];
};
