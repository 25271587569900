import axios, { AxiosResponse } from 'axios';

import {INewsItem} from '../types/api';
import {LangType} from '../types/lang';
import {getLang} from './utils';

interface LoadNewsResult {
  news?: INewsItem[];
  message?: string;
};

export const loadNews = async (token: string, langType: LangType): Promise<INewsItem[]> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<LoadNewsResult>('/api/v1.0/news', {headers})
    .then((response: AxiosResponse<LoadNewsResult>) => {
      if(response.data && response.data.news) return response.data.news;
      if(response.data && response.data.message)
        throw Error(`Load User error: ${response.data.message}`);
      throw Error(`Load User error: ${response.statusText}`);
    });
};

export const searchNews = async (token: string, langType: LangType, query: string): Promise<INewsItem[]> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<LoadNewsResult>(`/api/v1.0/news/search?q=${query}`, {headers})
    .then((response: AxiosResponse<LoadNewsResult>) => {
      if(response.data && response.data.news) return response.data.news;
      if(response.data && response.data.message)
        throw Error(`Load User error: ${response.data.message}`);
      throw Error(`Load User error: ${response.statusText}`);
    });
};
