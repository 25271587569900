import React, {Component} from 'react';

import {IGenusExt, IGenus} from '../types/api';
import {loadExistTreeByGenusId} from '../services/gen.service';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';

interface GenusProps {
  genus: IGenusExt | IGenus;
  isDemo: boolean;
  onSelect: () => void;
};

interface GenusState {};

export class Genus extends Component<GenusProps, GenusState> {
  static contextType = StoreContext;

  select() {
    loadExistTreeByGenusId(this.props.genus.id).then((data: any) => {
      const tree = document.getElementById('tree') as HTMLIFrameElement;
      if(tree) {
        const treeWnd = tree.contentWindow as any;
        treeWnd.buildExistTree(data);
        window.setTimeout(() => {
          const coords: any = treeWnd.getScrollCoordinates2();
          treeWnd.scrollTo(coords.x - 450, coords.y);
        }, 1000);
        window.scrollTo(0, 170);
      }
    });
  }
  
  render() {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.genus;
    const cls = `right-part-list ${this.props.isDemo ? 'demo' : ''}`
    return (
      <li className="item-list-tree">
        <div className="left-part-list">
          <p>{this.props.genus.name_in_list}</p>
        </div>
        {this.props.genus.in_work ? (
          <div className="right-part-list">{lang.inwork}</div>
        ) : (
          <div className={cls}>
            {!this.props.isDemo && <a href={undefined} onClick={() => this.select()} ><img src="images/easy.png" alt="" /></a>}
            <a href={undefined} onClick={() => this.props.onSelect()}><img src="images/icon-page.png" alt="" /></a>
          </div>
        )}
      </li>
    );
  }
}
