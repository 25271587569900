import React, {Component, FormEvent} from 'react';
import Autosuggest, {SuggestionsFetchRequestedParams, ChangeEvent} from 'react-autosuggest';
import {observer} from 'mobx-react';

import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {LayoutInner} from '../components/LayoutInner';
import {loadExistTreeByGenusId, search, getGenusWithAuthor, updateUserGenus, tooltips} from '../services/gen.service';
import {Menu} from '../components/Menu';
import {Zhuz} from '../components/Zhuz';
import {IZhuz, IGenusExt, IAuthor, ITribe, IGenus, ITooltip} from '../types/api';
import {SetGenusModal} from '../components/SetGenusModal';
import {EmptyGenusModal} from '../components/EmptyGenusModal';
import {Genus} from '../components/Genus';
import {DemoGenusModal} from '../components/DemoGenusModal';

interface ChangeGenusEvent {
  lastLevel?: boolean;
  genus: number | null;
  has_author?: boolean;
  has_subscription?: boolean;
};

interface TreeProps {};

interface TreeState {
  search: string;
  isSearch: boolean;
  isUserLoaded: boolean;
  selected: IAuthor | null;
  empty: string;
  demo: boolean;
  tooltips: ITooltip[];
};

@observer
export class Tree extends Component<TreeProps, TreeState> {
  static contextType = StoreContext;

  constructor(props: TreeProps) {
    super(props);
    this.state = {
      search: '',
      isSearch: false,
      isUserLoaded: false,
      selected: null,
      empty: '',
      demo: false,
      tooltips: []
    };
  }

  componentDidMount() {
    const tree = document.getElementById('tree') as HTMLIFrameElement;
    if(!tree) return;
    tree.onload = () => {
      this.buildByUser();
      (tree.contentWindow as any).setCallback(this.onHash);
    };
  }

  componentDidUpdate() {
    const tree = document.getElementById('tree') as HTMLIFrameElement;
    if(!tree) return;
    const treeWnd = tree.contentWindow as any;
    if(treeWnd.loadedSuccess) {
      this.buildByUser();
    }
  }

  buildByUser() {
    if(this.state.isUserLoaded) return;
    const appState = this.context as AppState;
    const user = appState.user.user;
    if(user && user.genus_id) {
      this.setState({isUserLoaded: true});
      loadExistTreeByGenusId(user.genus_id).then((data: any) => {
        const tree = document.getElementById('tree') as HTMLIFrameElement;
        if(tree) {
          const treeWnd = tree.contentWindow as any;
          treeWnd.buildExistTree(data);
          window.setTimeout(() => {
            const coords: any = treeWnd.getScrollCoordinates2();
            treeWnd.scrollTo(coords.x - 450, coords.y);
          }, 1000);
        }
      });
    }
  }

  loadAuthor = (genusId: number) => {
    const checkGenus = (genus: IGenusExt): void => {
      if(genus.id === genusId) {
        if(genus.author)
          this.setState({selected: genus.author});
        else
          this.setState({empty: genus.name});
        return;
      }
      if(genus.children && genus.children.length > 0) {
        genus.children.forEach(checkGenus);
      }
      // if(genus.id === genusId && genus.author) {
      //   this.setState({selected: genus.author});
      // } else if(genus.children && genus.children.length > 0) {
      //   genus.children.forEach(checkGenus);
      // }
    }
    
    getGenusWithAuthor(genusId).then((zhuzes: IZhuz[]) => {
      zhuzes.forEach((zhuz: IZhuz) => {
        if(zhuz.children && zhuz.children.length > 0) {
          zhuz.children.forEach((tribe: ITribe) => {
            if(tribe.children && tribe.children.length > 0) {
              tribe.children.forEach(checkGenus);
            }
          });
        }
      });
    });
  }

  onHash = (event: ChangeGenusEvent) => {
    if(event.genus !== null && event.lastLevel) {
      this.loadAuthor(event.genus);
    }
  }

  searchChange = (event: FormEvent<HTMLInputElement>, params: any) => {
    const resp = params as ChangeEvent;
    if(resp && resp.newValue) {
      this.setState({search: resp.newValue, isSearch: false});
    } else {
      this.setState({search: event.currentTarget.value, isSearch: false});
    }
  };

  searchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const query = this.state.search;
    if(query.length < 4) return;
    const tree = document.getElementById('tree') as HTMLIFrameElement;
    if(!tree) return;
    const treeWnd = tree.contentWindow as any;
    if(this.state.isSearch) {
      treeWnd.nextSearchResult();
    } else {
      search(query).then((result) => {
        treeWnd.setSearch(result);
        window.setTimeout(() => {
          const coords: any = treeWnd.getScrollCoordinates2();
          treeWnd.scrollTo(coords.x - 450, coords.y);
        }, 1000);
        this.setState({isSearch: true});
      });
    }
  }

  onSelect = (genus: IGenusExt) => {
    this.loadAuthor(genus.id);
  }

  selectDemo = () => {
    this.openDemo();
  }

  onCloseModal = () => {
    this.setState({selected: null});
  }

  onSuccessModal = (genusId: number) => {
    this.setState({selected: null});
    const appState = this.context as AppState;
    updateUserGenus(genusId, appState.auth.token, appState.lang.langType)
      .then(() => {
        window.location.href = '/geneology';
      });
  }

  emptyClose = () => {
    this.setState({empty: ''});
  }

  openDemo = () => {
    this.setState({demo: true});
  }

  closeDemo = () => {
    this.setState({demo: false});
  }

  goToDemo = (demoId: number) => {
    this.closeDemo();
    this.onSuccessModal(demoId);
  }

  onDemo = () => {
    this.emptyClose();
    this.selectDemo();
  }

  tooltipsFetch = (event: SuggestionsFetchRequestedParams) => {
    const
      appState = this.context as AppState,
      langType = appState.lang.langType,
      search = event.value;
    if(search && search.length > 0)
      tooltips(langType, search)
        .then((tooltips: ITooltip[]) => {
          this.setState({tooltips});
        });
  }

  tooltipsClear = () => {
    this.setState({tooltips: []});
  }
  
  render() {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.tree;
    const isEmpty = this.state.empty !== '';
    const modal = this.state.selected !== null ? (
      <SetGenusModal genus={this.state.selected.genus_id} author={this.state.selected} onClose={this.onCloseModal} onSuccess={this.onSuccessModal}></SetGenusModal>
    ) : null;
    const demo = appState.tree.genusList.demo;
    const inputProps = {
      placeholder: lang.search,
      value: this.state.search || '',
      onChange: this.searchChange
    };
    const tips = this.state.tooltips;
    return (
      <React.Fragment>
        <LayoutInner>
          <section className="content-tree-page">
            <div className="wrap">
              <Menu />
              <div className="main-article-content">
                <h1 className="news-title">{lang.choose}</h1>
                <form className="search" onSubmit={this.searchSubmit}>
                  {/* <input type="search" placeholder={lang.search} className="input" onChange={this.searchChange} /> */}
                  <Autosuggest
                    suggestions={tips}
                    onSuggestionsFetchRequested={this.tooltipsFetch}
                    onSuggestionsClearRequested={this.tooltipsClear}
                    getSuggestionValue={(value: ITooltip) => value.name}
                    renderSuggestion={(value: ITooltip) => <div>{value.name}</div>}
                    inputProps={inputProps}
                  />
                  <input type="submit" className="submit" value="" />
                </form>
                <div className="content-tree-rod">
                  <iframe id="tree" src={appState.user.user !== null ? '/treeSource/tree.html' : undefined} width="100%" height="500px"></iframe>
                </div>
              </div>
              <h3 className="list-create-pages">{lang.list}</h3>
              {demo && <Genus isDemo={true} genus={demo} onSelect={this.selectDemo}></Genus>}
              <div className="accordion accordion-rod">
                <div className="accordion__wrapper">
                  {appState.tree.genusList.zhuzes.map((zhuz: IZhuz) => <Zhuz key={zhuz.id} zhuz={zhuz} onSelect={this.onSelect}></Zhuz>)}
                </div>
              </div>
            </div>
          </section>
        </LayoutInner>
        {modal}
        {isEmpty && <EmptyGenusModal genus={this.state.empty} onClose={this.emptyClose} onDemo={this.onDemo}></EmptyGenusModal>}
        {this.state.demo && demo && <DemoGenusModal onClose={this.closeDemo} onSuccess={() => this.goToDemo(demo.id)}></DemoGenusModal>}
      </React.Fragment>
    );
  }
};
