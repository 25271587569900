import axios, { AxiosResponse } from 'axios';

import {IGeo} from '../types/api';
import {LangType} from '../types/lang';
import {getLang} from './utils';
import {GeoCoords} from '../types/models';

interface GetCardGeoResult {
  user?: IGeo;
  message?: string;
  error?: string;
};

export const getCardGeo = async (token: string, langType: LangType, cardId: number): Promise<IGeo> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<GetCardGeoResult>(`/api/v1.0/card/${cardId}/location`, {headers})
    .then((response: AxiosResponse<GetCardGeoResult>) => {
      if(response.data && response.data.user) return response.data.user;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface GetNearGeoResult {
  users_near?: IGeo[];
  message?: string;
  error?: string;
}

export const getNearGeo = async (token: string, langType: LangType, geo: GeoCoords): Promise<IGeo[]> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.post<GetNearGeoResult>(`/api/v1.0/user/location/near`, {latitude: geo.latitude, longitude: geo.longitude}, {headers})
    .then((response: AxiosResponse<GetNearGeoResult>) => {
      if(response.data && response.data.users_near) return response.data.users_near;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface UpdateGeoResult {
  success?: boolean;
  message?: string;
  error?: string;
}

export const updateGeo = async (token: string, langType: LangType, geo: GeoCoords): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.put<UpdateGeoResult>(`/api/v1.0/user/location`, {latitude: geo.latitude, longitude: geo.longitude}, {headers})
    .then((response: AxiosResponse<UpdateGeoResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};
