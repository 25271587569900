import React, {Component} from 'react';

import {Genus} from './Genus';
import {IZhuz, IGenusExt} from '../types/api';

export interface ZhuzProps {
  zhuz: IZhuz;
  onSelect: (genus: IGenusExt) => void;
};

export interface ZhuzState {
  isOpen: boolean;
};

export class Zhuz extends Component<ZhuzProps, ZhuzState> {

  constructor(props: ZhuzProps) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {
    const expanded = this.state.isOpen ? 'expanded' : '';
    const zhuzClass = `${expanded} accordion__question`;

    const genuses = this.state.isOpen ? (
      <div className="accordion__answer accordion__answer-rod">
        <ul>
          {this.props.zhuz.genus_list.map((genus: IGenusExt) => <Genus isDemo={false} key={genus.id} genus={genus} onSelect={() => this.props.onSelect(genus)}></Genus>)}
        </ul>
      </div>
    ) : null;
    
    return (
      <div className="accordion__item">
        <div className={zhuzClass} onClick={this.toggle}>
          <h2>{this.props.zhuz.name}</h2>
        </div>
        {genuses}
      </div>
    );
  }
};
