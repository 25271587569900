import React, {Component} from 'react';
import {observer} from 'mobx-react';

import {ModalBack} from './ModalBack';
import {agreement} from '../services/auth.service';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';

interface AgreementModalProps {
  onClose: () => void;
};

interface AgreementModalState {
  html: string;
};

@observer
export class AgreementModal extends Component<AgreementModalProps, AgreementModalState> {
  static contextType = StoreContext;

  constructor(props: AgreementModalProps) {
    super(props);
    this.state = {
      html: ''
    };
  }

  componentDidMount() {
    const
      appState = this.context as AppState,
      langType = appState.lang.langType;
    agreement(langType).then((html: string) => this.setState({html}));
  }
  
  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.agreement,
      isHtml = () => this.state.html === '' ? false : true;
    return (
      <ModalBack onClick={() => this.props.onClose()}>
        <div className="agreement-modal" onClick={(event: any) => event.stopPropagation()}>
          <div className="agreement-modal-container">
            <div className="agreement-text">
              {isHtml() ? (
                <div dangerouslySetInnerHTML={{__html: this.state.html}}></div>
              ) : (
                <div className="spinner2"></div>
              )}
            </div>
            {isHtml() && <div className="agreement-modal-button" onClick={() => this.props.onClose()}>{lang.close}</div>}
          </div>
          <div className="modal-close-btn" onClick={this.props.onClose}></div>
        </div>
      </ModalBack>
    )
  }
}
