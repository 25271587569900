export enum GenealogyType {
  Genus = 'genus',
  Zhuz = 'zhuz',
  Tribe = 'tribe'
}

export interface IAuth {
  phone?: string;
  password?: string;
};

export interface IUpdateUser {
  name?: string;
  email?: string;
  is_push_enabled?: number;
  show_location?: boolean;
  is_receive_messages?: boolean;
  genus_id?: number;
};

export interface UserSettings {
  show_location: boolean;
  is_receive_messages: boolean;
  female_mode: boolean;
};

export interface CardEditModel {
  gender?: string;
  surname?: string;
  name?: string;
  middle_name?: string;
  date_of_birth?: string | null;
  date_of_death?: string | null;
  phone?: string;
  address?: string;
  email?: string;
  photo?: string | null;
  photo_url?: string;
  photo_id?: number;
  personal_card_id?: number;
};

export interface HistoryEditModel {
  title_ru: string;
  title_en: string;
  title_kk: string;
  body_ru: string;
  body_en: string;
  body_kk: string;
};

export interface GeoCoords {
  latitude: number;
  longitude: number;
};

export interface ChatMessage {
  message?: string;
  type?: 'photo' | 'text' | 'video';
  media_id?: number;
};

export interface ChatMessages {
  direction: 'desc' | 'asc';
	from_message_id: number | null;
	search: string | null;
	limit: number;
}

export interface ChatMessageStatus {
  status: 'new' | 'read';
};

export interface PhoneCode {
  phone: string;
};

export interface RecoverPassword {
  phone: string;
  code: string;
  password: string;
};
