import React, {FC} from 'react';
import {observer} from 'mobx-react';

import {ModalBack} from './ModalBack';
import {useState} from './Store';

interface ModerationModalProps {
  onClose: () => void;
};

export const ModerationModal: FC<ModerationModalProps> = observer(
  (props: ModerationModalProps) => {
    const appState = useState();
    const lang = appState.lang.lang.moderationModal;
    return (
      <ModalBack onClick={() => props.onClose()}>
        <div className="add-card-modal" onClick={(event: any) => event.stopPropagation()}>
          <div className="modal-close-btn" onClick={props.onClose}></div>
          <div className="add-card-title">{lang.request}</div>
          <div className="add-card-ok-button" onClick={() => props.onClose()}>{lang.close}</div>
        </div>
      </ModalBack>
    )
  }
);
