import {observable, action} from 'mobx';

import {loadCard} from '../services/card.service';
import {ApiState} from './api.state';
import {IPersonalCardFull} from '../types/api';
import {LangType} from '../types/lang';

export class CardState extends ApiState {
  @observable card: IPersonalCardFull | null = null;

  @action toDefault() {
    super.toDefault();
    this.card = null;
  }

  @action load(token: string, langType: LangType): Promise<IPersonalCardFull> {
    this.isLoading = true;
    this.card = null;
    this.error = '';
    const result = loadCard(token, langType)
    result
      .then(this.loadSuccess)
      .catch(this.loadFailed);
    return result;
  }

  @action.bound private loadSuccess(card: IPersonalCardFull) {
    if(!this.isLoading) throw '';
    this.isLoading = false;
    this.card = card;
  }

  @action.bound private loadFailed(errorData: any) {
    this.isLoading = false;
    if(typeof errorData === 'string') this.error = errorData;
    else this.error = 'Card error';
  }
}
