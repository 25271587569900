import React, {Component, FormEvent} from 'react';
import {Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';

import {IAuth} from '../types/models';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {RecoverPasswordModal} from './RecoverPasswordModal';

interface SigninProps {};

interface SigninState {
  auth: IAuth;
  isRecover: boolean;
};

@observer
export class Signin extends Component<SigninProps, SigninState> {
  static contextType = StoreContext;

  constructor(props: SigninProps) {
    super(props);
    this.state = {
      auth: {
        phone: '',
        password: ''
      },
      isRecover: false
    };
  }

  changePhone(event: FormEvent<HTMLInputElement>) {
    this.setState({auth: {
      ...this.state.auth,
      phone: event.currentTarget.value
    }});
  }

  changePassword(event: FormEvent<HTMLInputElement>) {
    this.setState({auth: {
      ...this.state.auth,
      password: event.currentTarget.value
    }});
  }

  openRecover = () => {
    this.setState({isRecover: true});
  }

  recoverClose = () => {
    this.setState({isRecover: false});
  }

  recoverSuccess = () => {
    this.setState({isRecover: false});
  }

  signin() {
    const appState = this.context as AppState;
    if(this.state.auth.phone && this.state.auth.password) {
      appState.signin(this.state.auth);
    }
  }

  render() {
    const appState = this.context as AppState;
    if(appState.auth.isAuth && !appState.registration.isRegistrate) {
      return (<Redirect to={{pathname: `/usermain`}}></Redirect>);
    }
    const
      {signin} = appState.lang.lang,
      isLoading = appState.auth.isLoading || appState.user.isLoading || appState.card.isLoading;
    return (
      <>
        <div className="form-tab">
          <div className="filds">
            {appState.registration.hasError ? (<div className="error-msg">{signin.error}</div>) : (undefined)}
            <p>{signin.phone}</p>
            <input id="phone" value={this.state.auth.phone} onChange={this.changePhone.bind(this)} type="text" placeholder="+7( ____ ) _____________" />
          </div>
          <div className="filds">
            <p>{signin.password}</p>
            <input type="password" value={this.state.auth.password} onChange={this.changePassword.bind(this)} />
          </div>
          <a className="fogot_pass" onClick={this.openRecover}>{signin.forget}</a>
          <input id="main-form-btn" type="button" value={signin.enter} onClick={this.signin.bind(this)} />
          {isLoading ? (<div className="spinner2"></div>) : (undefined)}
        </div>
        {this.state.isRecover &&
          <RecoverPasswordModal onClose={this.recoverClose} onSuccess={this.recoverSuccess}></RecoverPasswordModal>
        }
      </>
    );
  }
};
