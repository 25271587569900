import React, {Component, FormEvent} from 'react';
import {Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';

import {IAuth} from '../types/models';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {AgreementModal} from './AgreementModal';

interface RegistrationProps {};

interface RegistrationState {
  auth: IAuth;
  error: string;
  dirtyPassword: boolean;
  dirtyLogin: boolean;
  agreement: boolean;
};

@observer
export class Registration extends Component<RegistrationProps, RegistrationState> {
  static contextType = StoreContext;
  
  constructor(props: RegistrationProps) {
    super(props);
    this.state = {
      auth: {
        phone: '',
        password: ''
      },
      error: '',
      dirtyLogin: false,
      dirtyPassword: false,
      agreement: false
    };
  }

  changePhone(event: FormEvent<HTMLInputElement>) {
    this.setState({auth: {
      ...this.state.auth,
      phone: event.currentTarget.value,
    }, dirtyLogin: true});
  }

  changePassword(event: FormEvent<HTMLInputElement>) {
    this.setState({auth: {
      ...this.state.auth,
      password: event.currentTarget.value
    }});
  }

  registration() {
    const appState = this.context as AppState;
    this.setState({dirtyPassword: true, dirtyLogin: false});
    if(this.state.auth.phone && this.state.auth.password && this.state.auth.password.length >= 4) {
      appState.registration.load(this.state.auth).then(() => appState.signin(this.state.auth));
    }
  }

  isExistError(): boolean {
    const appState = this.context as AppState;
    return appState.registration.hasError && !this.state.dirtyLogin;
  }

  isLengthError(): boolean {
    return this.state.dirtyPassword && !!this.state.auth.password && this.state.auth.password.length < 4;
  }

  openAgreement() {
    this.setState({agreement: true});
  }

  closeAgreement() {
    this.setState({agreement: false});
  }
  
  render() {
    const appState = this.context as AppState;
    if(appState.registration.isRegistrate && appState.auth.isAuth) {
      return (<Redirect to={{pathname: `/tree`}}></Redirect>);
    }
    const
      {registration} = appState.lang.lang,
      {isLoading} = appState.registration;
    return (
      <>
        <div className="form-tab">
          <div className="filds">
            {this.isExistError() ? (<div className="error-msg">{registration.existError}</div>) : (undefined)}
            <p>{registration.phone}</p>
            <input id="phone" value={this.state.auth.phone} onChange={this.changePhone.bind(this)} type="text" placeholder="+7( ____ ) _____________ " />
          </div>
          <div className="filds">
            {this.isLengthError() ? (<div className="error-msg">{registration.lengthError}</div>) : (undefined)}
            <p>{registration.password}</p>
            <input type="password" value={this.state.auth.password} onChange={this.changePassword.bind(this)} />
          </div>
          <div className="filds agreement">
            {registration.agreementOne} <a onClick={this.openAgreement.bind(this)}>{registration.agreementTwo}</a>
          </div>
          <input id="main-form-btn" type="button" value={registration.registrate} onClick={this.registration.bind(this)} />
          {isLoading ? (<div className="spinner2"></div>) : (undefined)}
        </div>
        {this.state.agreement &&
          <AgreementModal onClose={this.closeAgreement.bind(this)}></AgreementModal>
        }
      </>
    );
  }
};
