import React, {Component, ChangeEvent, FormEvent} from 'react';
import {observer} from 'mobx-react';

import {LayoutInner} from '../components/LayoutInner';
import {Menu} from '../components/Menu';
import {NewsItem} from '../components/NewsItem';
import {INewsItem} from '../types/api';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';

interface NewsProps {};

interface NewsState {
  query: string
};

@observer
export class News extends Component<NewsProps, NewsState> {
  static contextType = StoreContext;

  constructor(props: NewsProps) {
    super(props);
    this.state = {
      query: ''
    };
  }

  searchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.search();
  }

  search = () => {
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    appState.news.load(token, langType, this.state.query);
  }

  searchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState({query: event.target.value}, () => {
      if(query === '') this.search();
    });
  }

  render() {
    const appState = this.context as AppState;
    const
      lang = appState.lang.lang.news,
      {news, isLoading} = appState.news;
    return (
      <LayoutInner>
        <section className="content-article">
          <div className="wrap">
            <Menu />
            <div className="main-article-content">
              <h1 className="news-title">{lang.title}</h1>
              <form className="search" onSubmit={this.searchSubmit}>
                <input type="search" placeholder="Поиск по новостям" className="input" value={this.state.query} onChange={this.searchChange} />
                <input type="submit" className="submit" value="" />
              </form>
              {news.map((item: INewsItem) => (<NewsItem item={item} key={item.id} />))}
              {isLoading ? (<div className="spinner2"></div>) : (undefined)}
            </div>
            {/* <NewsPagination/> */}
          </div>
        </section>
      </LayoutInner>
    );
  }
};
