import axios, { AxiosResponse } from 'axios';

import {IAuth, PhoneCode, RecoverPassword} from '../types/models';
import {LangType} from '../types/lang';
import {getLang} from './utils';

interface SigninResult {
  token?: string;
  error?: string;
};

export const signin = async (auth: IAuth): Promise<string> => {
  return axios.post<SigninResult>('/api/v1.0/login', auth)
    .then((response: AxiosResponse<SigninResult>) => {
      if(response.data && response.data.token) return response.data.token;
      if(response.data && response.data.error)
        throw Error(`Signin error: ${response.data.error}`);
      throw Error(`Signin error: ${response.statusText}`);
    });
};

interface RegistrationResult {
  token?: string;
  success?: boolean;
  error?: string;
};

export const registration = async (auth: IAuth): Promise<string> => {
  return axios.post<RegistrationResult>('/api/v1.0/register', auth)
    .then((response: AxiosResponse<RegistrationResult>) => {
      if(response.data && response.data.token) return response.data.token;
      if(response.data && response.data.error)
        throw Error(`Registration error: ${response.data.error}`);
      throw Error(`Registration error: ${response.statusText}`);
    });
};

interface GetCodeResult {
  success?: boolean;
  error?: string;
  message?: string;
};

export const getCode = async (langType: LangType, data: PhoneCode): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.post<GetCodeResult>(`/api/v1.0/recovery-code-send`, data, {headers})
    .then((response: AxiosResponse<GetCodeResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface GetAnotherCodeResult {
  success?: boolean;
  error?: string;
  message?: string;
};

export const getAnotherCode = async (langType: LangType, data: PhoneCode): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.post<GetAnotherCodeResult>(`/api/v1.0/resend-confirm-sms`, data, {headers})
    .then((response: AxiosResponse<GetAnotherCodeResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface RecoverPasswordResult {
  success?: boolean;
  error?: string;
  message?: string;
};

export const recoverPassword = async (langType: LangType, data: RecoverPassword): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.post<RecoverPasswordResult>(`/api/v1.0/recovery-password`, data, {headers})
    .then((response: AxiosResponse<RecoverPasswordResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface AgreementResult {
  html?: string;
  error?: string;
  message?: string;
};

export const agreement = async (langType: LangType): Promise<string> => {
  const lang = getLang(langType);
  const headers = {
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.get<AgreementResult>('/api/v1.0/agreement', {headers})
    .then((response: AxiosResponse<AgreementResult>) => {
      if(response.data && response.data.html) return response.data.html;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};
