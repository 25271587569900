import React, {Component, ChangeEvent} from 'react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import {IPersonalCardFull, IHistory} from '../types/api';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {ModalBack} from './ModalBack';
import {HistoryEditModel} from '../types/models';
import {removeHistory, updateHistory, addHistory} from '../services/card.service';

interface UserHistoryProps {
  card: IPersonalCardFull | null;
  onModerate: () => void;
};

interface UserHistoryState {
  id: number | null;
  titleRu: string;
  bodyRu: string;
  titleKz: string;
  bodyKz: string
  isEdit: boolean;
};

export class UserHistory extends Component<UserHistoryProps, UserHistoryState> {
  static contextType = StoreContext;

  constructor(props: UserHistoryProps) {
    super(props);
    this.state = {
      titleRu: '',
      bodyRu: '',
      titleKz: '',
      bodyKz: '',
      id: null,
      isEdit: false
    };
  }

  changeTitleRu = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({titleRu: event.target.value});
  }

  changeBodyRu = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({bodyRu: event.target.value})
  }

  changeTitleKz = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({titleKz: event.target.value});
  }

  changeBodyKz = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({bodyKz: event.target.value})
  }

  onEdit = (history: IHistory) => {
    this.setState({isEdit: true, titleRu: history.title, bodyRu: history.body, id: history.id});
  }

  addHistory = () => {
    this.setState({isEdit: true, titleRu: '', bodyRu: '', titleKz: '', bodyKz: '', id: null});
  }

  onClose = () => {
    this.setState({isEdit: false, titleRu: '', bodyRu: '', titleKz: '', bodyKz: '', id: null});
  }

  onSuccess = () => {
    if(!this.props.card) return;
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    if(this.state.id) {
      updateHistory(token, langType, this.state.id, {
        title_ru: this.state.titleRu,
        title_en: this.state.titleRu,
        title_kk: this.state.titleKz,
        body_ru: this.state.bodyRu,
        body_en: this.state.bodyRu,
        body_kk: this.state.bodyKz
      }).then(() => {
        this.setState({isEdit: false, titleRu: '', bodyRu: '', titleKz: '', bodyKz: '', id: null});
        this.props.onModerate();
      });
    } else {
      addHistory(token, langType, this.props.card.id, {
        title_ru: this.state.titleRu,
        title_en: this.state.titleRu,
        title_kk: this.state.titleKz,
        body_ru: this.state.bodyRu,
        body_en: this.state.bodyRu,
        body_kk: this.state.bodyKz
      }).then(() => {
        this.setState({isEdit: false, titleRu: '', bodyRu: '', titleKz: '', bodyKz: '', id: null});
        this.props.onModerate();
      });
    }
  }

  onRemove = () => {
    if(!this.props.card) return;
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    if(this.state.id) {
      removeHistory(token, this.state.id, langType).then(() => {
        this.setState({isEdit: false, titleRu: '', bodyRu: '', titleKz: '', bodyKz: '', id: null});
        this.props.onModerate();
      });
    } else {
      this.setState({isEdit: false, titleRu: '', bodyRu: '', titleKz: '', bodyKz: '', id: null});
    }
  }

  render() {
    const histories = this.props.card ? this.props.card.histories : [];
    const canEdit = this.props.card ? this.props.card.allow_edit : false;
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.user.historyTab;
    return (
      <div id="second">
        <div className="wrap histories">
          {histories.length === 0 && <h1>{lang.nohistory}</h1>}
          {histories.map((history: IHistory) => (
            <div className="text-history">
              <div className="text-history-title">{history.title}</div>
              <p>{history.body}</p>
              {canEdit &&
                <a className="edit-history" onClick={() => this.onEdit(history)}><img src="/images/replace.png" alt="" /></a>
              }
            </div>
          ))}
          <div className="user-contact-tools">
            {canEdit &&
              <a className="btn-add-genus" onClick={this.addHistory}>+ {lang.add}</a>
            }
          </div>
        </div>
        {this.state.isEdit &&
          <ModalBack onClick={this.onClose}>
            <div className="card-editor-modal history-modal" onClick={(event: any) => event.stopPropagation()}>
              <div className="card-editor-container">
                <form>
                  <div className="card-editor-row">
                    <FormControl className="card-editor-input">
                      <InputLabel htmlFor="component-simple">{lang.titleRu}</InputLabel>
                      <Input id="component-simple" value={this.state.titleRu} onChange={this.changeTitleRu} />
                    </FormControl>
                  </div>
                  <div className="card-editor-row">
                    <FormControl className="card-editor-input">
                      <TextField multiline={true} rows={4} placeholder={lang.textRu} value={this.state.bodyRu} onChange={this.changeBodyRu} />
                    </FormControl>
                  </div>
                  <div className="card-editor-row">
                    <FormControl className="card-editor-input">
                      <InputLabel htmlFor="component-simple">{lang.titleKz}</InputLabel>
                      <Input id="component-simple" value={this.state.titleKz} onChange={this.changeTitleKz} />
                    </FormControl>
                  </div>
                  <div className="card-editor-row">
                    <FormControl className="card-editor-input">
                      <TextField multiline={true} rows={4} placeholder={lang.textKz} value={this.state.bodyKz} onChange={this.changeBodyKz} />
                    </FormControl>
                  </div>
                </form>
              </div>
              {this.state.id && <div className="card-editor-save-button card-editor-remove-button" onClick={this.onRemove}>{lang.remove}</div>}
              <div className="card-editor-save-button" onClick={this.onSuccess}>{lang.save}</div>
              <div className="modal-close-btn" onClick={this.onClose}></div>
            </div>
          </ModalBack>
        }
      </div>
    );
  }
};
