import React, {FC, PropsWithChildren, Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';

import {Footer} from './Footer';
import {Header} from './Header';
import {useState} from './Store';

type LayoutOuterProps = PropsWithChildren<{}>;

export const LayoutOuter: FC<LayoutOuterProps> = observer(
  (props: LayoutOuterProps) => {
    const appState = useState();
    if(!appState.auth.isAuth && window.location.pathname !== '/') {
      return (<Redirect to="/"></Redirect>);
    }
    const isTreePath: boolean = window.location.pathname.slice(0, 5) === '/tree';
    if(appState.user.user && !appState.user.user.genus_id && !isTreePath) {
      return (<Redirect to="/tree"></Redirect>);
    }
    return (
      <Fragment>
        <section className="main bg-tree">
          <Header />
          <div className="wrap">
            {props.children}
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
);
