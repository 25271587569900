import axios, { AxiosResponse } from 'axios';

import {ChatMessage, ChatMessages, ChatMessageStatus} from '../types/models';
import {LangType} from '../types/lang';
import {getLang} from './utils';
import {IChatMessage, ICahtLastMsg, IChatMsgs, IAmountInfo, IChatMedia} from '../types/api';

interface SendToChatResult {
  success?: boolean;
  error?: string;
  message?: IChatMessage | string;
};

export const sendToChat = async (token: string, langType: LangType, chatId: number, message: ChatMessage): Promise<IChatMessage> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.post<SendToChatResult>(`/api/v1.0/chats/${chatId}/send`, message, {headers})
    .then((response: AxiosResponse<SendToChatResult>) => {
      if(response.data && response.data.success && response.data.message && (typeof response.data.message !== 'string')) return response.data.message;
      if(response.data && response.data.message && (typeof response.data.message === 'string'))
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface GetChatListResult {
  chats: ICahtLastMsg[];
  error?: string;
  message?: string;
};

export const getChatList = async (token: string, langType: LangType): Promise<ICahtLastMsg[]> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<GetChatListResult>(`/api/v1.0/chats/list`, {headers})
    .then((response: AxiosResponse<GetChatListResult>) => {
      if(response.data && response.data.chats) return response.data.chats;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface GetChatMessages {
  chat?: IChatMsgs;
  error?: string;
  message?: string;
};

export const getChatMessages = async (token: string, langType: LangType, chatId: number, args: ChatMessages): Promise<IChatMsgs> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.post<GetChatMessages>(`/api/v1.0/chats/${chatId}/messages`, args, {headers})
    .then((response: AxiosResponse<GetChatMessages>) => {
      if(response.data && response.data.chat) return response.data.chat;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface GetAmountInfoResult extends IAmountInfo {
  error?: string;
  message?: string;
};

export const getAmountInfo = async (token: string, langType: LangType): Promise<IAmountInfo> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<GetAmountInfoResult>(`/api/v1.0/chats/messages/new`, {headers})
    .then((response: AxiosResponse<GetAmountInfoResult>) => {
      if(response.data && response.data.new) return response.data;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface GetChatByUserIdResult {
  chat?: ICahtLastMsg;
  error?: string;
  message?: string;
};

export const getChatByUserId = async (token: string, langType: LangType, userId: number): Promise<ICahtLastMsg> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<GetChatByUserIdResult>(`/api/v1.0/chats/getByUser/${userId}`, {headers})
    .then((response: AxiosResponse<GetChatByUserIdResult>) => {
      if(response.data && response.data.chat) return response.data.chat;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface GetChatByGenusIdResult {
  chat?: ICahtLastMsg;
  error?: string;
  message?: string;
};

export const getChatByGenusId = async (token: string, langType: LangType, genusId: number): Promise<ICahtLastMsg> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<GetChatByGenusIdResult>(`/api/v1.0/chats/getByGenus/${genusId}`, {headers})
    .then((response: AxiosResponse<GetChatByGenusIdResult>) => {
      if(response.data && response.data.chat) return response.data.chat;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface GetChatByIdResult {
  chat?: ICahtLastMsg;
  error?: string;
  message?: string;
};

export const getChatById = async (token: string, langType: LangType, id: number): Promise<ICahtLastMsg> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<GetChatByIdResult>(`/api/v1.0/chats/${id}`, {headers})
    .then((response: AxiosResponse<GetChatByIdResult>) => {
      if(response.data && response.data.chat) return response.data.chat;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface ChangeMsgStatusResult {
  message?: IChatMessage | string;
  error?: string;
};

export const changeMsgStatus = async (token: string, langType: LangType, msgId: number, status: ChatMessageStatus): Promise<IChatMessage> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.put<ChangeMsgStatusResult>(`/api/v1.0/chats/message/${msgId}/status`, status, {headers})
    .then((response: AxiosResponse<ChangeMsgStatusResult>) => {
      if(response.data && response.data.message && (typeof response.data.message !== 'string')) return response.data.message;
      if(response.data && response.data.message && (typeof response.data.message === 'string'))
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface ToOfflineResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const toOffline = (token: string, langType: LangType): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<ToOfflineResult>(`/api/v1.0/toOffline`, {headers})
    .then((response: AxiosResponse<ToOfflineResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface MuteChatResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const muteChat = (token: string, langType: LangType, chatId: number): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.put<MuteChatResult>(`/api/v1.0/chats/${chatId}/mute`, {headers})
    .then((response: AxiosResponse<MuteChatResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface UnmuteChatResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const unmuteChat = (token: string, langType: LangType, chatId: number): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.put<UnmuteChatResult>(`/api/v1.0/chats/${chatId}/unmute`, {headers})
    .then((response: AxiosResponse<UnmuteChatResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    });
};

interface UploadMediaResult {
  media?: IChatMedia;
  message?: string;
  error?: string;
};

export const uploadMedia = async (token: string, langType: LangType, data: FormData): Promise<IChatMedia> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang,
    'Content-Type': 'multipart/form-data'
  };
  return axios.post<UploadMediaResult>(`/api/v1.0/media`, data, {headers})
    .then((response: AxiosResponse<UploadMediaResult>) => {
      if(response.data && response.data.media) return response.data.media;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};

interface GetMediaResult {
  media?: IChatMedia;
  message?: string;
  error?: string;
};

export const getMedia = async (token: string, langType: LangType, mediaId: number): Promise<IChatMedia> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<GetMediaResult>(`/api/v1.0/media/${mediaId}`, {headers})
    .then((response: AxiosResponse<GetMediaResult>) => {
      if(response.data && response.data.media) return response.data.media;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};
