import React, {FC} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';

import {LayoutInner} from '../components/LayoutInner';
import {Menu} from '../components/Menu';
import {INewsItem} from '../types/api';
import {useState} from '../components/Store';

interface ArticleProps {};

export const RodArticle: FC<ArticleProps> = observer(
  (props: ArticleProps) => {
    const appState = useState();
    const lang = appState.lang.lang.article;
    const loading = 
      <LayoutInner>
        <section className="content-article">
          <div className="wrap">
            <Menu />
            <div className="main-article-content">
              <div className="spinner2"></div>
            </div>
            <div className="pagination">
              <div className="all-news">
                <Link className="post-back-link" to="/rodnews">
                  <img src="/images/link-arrow-back.webp" alt="" />
                  <span>{lang.allnews}</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </LayoutInner>;
    if(appState.rodnews.isLoading) return loading;
    const id: number = parseInt((props as any).match.params.articleId);
    const article = appState.rodnews.news.find((item: INewsItem) => item.id === id) || null;
    // if(!article) return (<Redirect to={'/rodnews'}></Redirect>);
    if(!article) return loading;
    const date = new Date(article.created_at * 1000);
    const day = date.getDate();
    const dayStr = day > 9 ? day.toString() : `0${day}`;
    const month = date.getMonth() + 1;
    const monthStr = month > 9 ? month.toString() : `0${month}`;
    const newsDate = `${dayStr}.${monthStr}`;
    const year = date.getFullYear().toString().slice(2, 4);
    const isImage = !!article.photo;
    const image = isImage ? (
      <div className="image-article">
        <img src={article.photo} alt="" />
      </div>
    ) : null;
    return (
      <LayoutInner>
        <section className="content-article">
          <div className="wrap">
            <Menu />
            <div className="main-article-content">
              <h1 className="article-title">{article.name}</h1>
              {/* <div className="post__tag">{article.tag}</div> */}
              <div className="info-article">
                <div className="date-article">
                  <p className="post-date">{newsDate}</p>
                  <p className="post-day">{year}</p>
                </div>
                {image}
              </div>
              <div className="text-article" dangerouslySetInnerHTML={{__html: article.body}}></div>
            </div>
            <div className="pagination">
              <div className="all-news">
                <Link className="post-back-link" to="/rodnews">
                  <img src="/images/link-arrow-back.webp" alt="" />
                  <span>{lang.allnews}</span>
                </Link>
              </div>
              <div className="pagination-social">
                <p>{lang.share}</p>
                <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}><img src="/images/facebook-share.webp" alt="" /></a>
                <a target="_blank" href={`https://vk.com/share.php?url=${window.location.href}`}><img src="/images/vk-share.webp" alt="" /></a>
                <a target="_blank" href={`https://www.instagram.com/`}><img src="/images/instagram-share.webp" alt="" /></a>
              </div>
            </div>
          </div>
        </section>
      </LayoutInner>
    );
  }
);
