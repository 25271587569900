import {observable, action} from 'mobx';

import {LangType, Lang} from '../types/lang';
import {ru} from '../lang/ru';
import {kz} from '../lang/kz';

export class LangState {
  @observable langType: LangType = LangType.RU;
  @observable lang: Lang = ru;

  @action setRu() {
    this.langType = LangType.RU;
    this.lang = ru;
  }

  @action setKz() {
    this.langType = LangType.KZ;
    this.lang = kz;
  }
}
