import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';

import {LayoutInner} from '../components/LayoutInner';
import {Menu} from '../components/Menu';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {UserLinks} from '../components/UserLinks';
import {UserFoto} from '../components/UserFoto';
import {UserHistory} from '../components/UserHistory';
import {UserVideo} from '../components/UserVideo';
import {UserContacts} from '../components/UserContacts';
import {IPersonalCardFull, IPersonLink } from '../types/api';
import {loadCardById, updateCard, addKid, addMother} from '../services/card.service';
import {CardEditorModal} from '../components/CardEditorModal';
import {CardEditModel} from '../types/models';
import {ModerationModal} from '../components/ModerationModal';
import {uploadImage} from '../services/card.service';

interface UserProps {};

type UserTabState = 'links' | 'history' | 'foto' | 'video' | 'contacts';

export interface UserState {
  tab: UserTabState;
  card: IPersonalCardFull | null;
  isEdit: boolean;
  isLoadCard: boolean;
  isModeration: boolean;
  isMotherEdit: boolean;
  isKidEdit: boolean;
  isCardEdit: boolean;
  cardForEdit: IPersonLink | null;
};

@observer
export class User extends Component<UserProps, UserState> {
  static contextType = StoreContext;

  constructor(props: UserProps) {
    super(props);
    this.state = {
      tab: 'links',
      card: null,
      isEdit: false,
      isLoadCard: false,
      isModeration: false,
      isMotherEdit: false,
      isKidEdit: false,
      isCardEdit: false,
      cardForEdit: null
    };
  }

  addMotherModal = () => {
    this.setState({isMotherEdit: true});
  }

  motherClose = () => {
    this.setState({isMotherEdit: false});
  }

  motherSuccess = (card: CardEditModel) => {
    delete card.gender;
    this.setState({isMotherEdit: false});
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    addMother(token, langType, card).then(() => {
      this.openModerate();
    });
  }

  addKidModal = () => {
    this.setState({isKidEdit: true});
  }

  kidClose = () => {
    this.setState({isKidEdit: false});
  }

  kidSuccess = (card: CardEditModel) => {
    this.setState({isKidEdit: false});
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    addKid(token, langType, card).then(() => {
      this.openModerate();
    });
  }

  getTabClass(tabState: UserTabState): string {
    if(this.isTab(tabState)) return 'active';
    return '';
  }

  isTab(tabState: UserTabState): boolean {
    return this.state.tab === tabState;
  }

  openModerate = () => {
    this.setState({isModeration: true});
  }

  editCard = (card: IPersonLink) => {
    this.setState({isCardEdit: true, cardForEdit: card});
  }

  editCardClose = () => {
    this.setState({isCardEdit: false, cardForEdit: null});
  }

  editCardSuccess = (card: CardEditModel) => {
    if(!this.state.cardForEdit) return;
    const
      appState = this.context as AppState;
    let data: {[key: string]: any} = {};
    let newModel = card as {[key: string]: any};
    let oldModel = this.state.cardForEdit as {[key: string]: any};
    for(let field in newModel) {
      if(field === 'photo_id' || field === 'photo_url') continue;
      if(newModel[field] !== oldModel[field]) data[field] = newModel[field];
    }
    updateCard(oldModel.id, appState.auth.token, appState.lang.langType, data).then(() => {
      this.setState({isCardEdit: true, cardForEdit: null, isModeration: true});
    });
  }

  getTab(card: IPersonalCardFull) {
    switch(this.state.tab) {
      case 'links': return <UserLinks card={card} addMother={this.addMotherModal} addKid={this.addKidModal} edit={this.editCard} />;
      case 'history': return <UserHistory card={card} onModerate={this.openModerate}/>;
      case 'foto': return <UserFoto onModerate={this.openModerate} card={card}/>;
      case 'video': return <UserVideo onModerate={this.openModerate} card={card}/>;
      case 'contacts': return <UserContacts card={card} onEdit={this.editOpen} onMyPage={this.setMyPage}/>;
    }
    return <></>;
  }

  setMyPage = () => {}

  getCardId(): number | null {
    let userId: number | null = null;
    const anyProps = this.props as any;
    if(
      anyProps.match &&
      anyProps.match.params &&
      anyProps.match.params.userId) {
      userId = parseInt(anyProps.match.params.userId);
      if(isNaN(userId)) userId = null;
    }
    return userId;
  }

  loadCard() {
    if(this.state.isLoadCard) return;
    this.setState({isLoadCard: true});
    const
      appState = this.context as AppState,
      cardId = this.getCardId();
    if(cardId)
      loadCardById(cardId, appState.auth.token, appState.lang.langType)
        .then((card) => {
          this.setState({card, isLoadCard: false});
        });
  }

  loadingView() {
    return (
      <LayoutInner>
        <section className="content-user-about">
          <div className="wrap">
            <Menu />
          </div>
        </section>
        <section className="user-info">
          <div className="wrap">
            <div className="wrap-user-about"><div className="spinner2"></div></div>
          </div>
        </section>
      </LayoutInner>
    );
  }

  noCardView() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.user;
    return (
      <LayoutInner>
        <section className="content-user-about">
          <div className="wrap">
            <Menu />
          </div>
        </section>
        <section className="user-info">
          <div className="wrap">
            <div className="wrap-user-about"><div className="nocard">{lang.nocard}</div></div>
          </div>
        </section>
      </LayoutInner>
    );
  }

  editOpen = () => {
    this.setState({isEdit: true});
  }

  editClose = () => {
    this.setState({isEdit: false});
  }

  editSuccess = (model: CardEditModel) => {
    const
      appState = this.context as AppState;
    let data: {[key: string]: any} = {};
    let newModel = model as {[key: string]: any};
    let stateModel = this.state.card as {[key: string]: any};
    let userModel = appState.card.card as {[key: string]: any};
    if(!stateModel && !userModel) {
      this.setState({isEdit: false});
      return;
    }
    let oldModel = stateModel || userModel;
    for(let field in newModel) {
      if(field === 'photo_id' || field === 'photo_url') continue;
      if(newModel[field] !== oldModel[field]) data[field] = newModel[field];
    }
    updateCard(oldModel.id, appState.auth.token, appState.lang.langType, data).then(() => {
      this.setState({isEdit: false, isModeration: true});
    });
  }

  moderationClose = () => {
    this.setState({isModeration: false});
  }

  getAvatarUrl = (card: IPersonalCardFull): string => {
    const emptyPhoto = '/images/no-image.png';
    return card.photo_url ? card.photo_url : emptyPhoto;
  }

  getExt(type: string): string {
    return type.split('/')[1];
  }

  changeAvatar = (card: IPersonalCardFull | null) => {
    if(!card || !card.allow_edit) return;
    const
      appState = this.context as AppState;
    
    const fileUpload = document.getElementById('avatarUpload') as HTMLInputElement;
    if(!fileUpload || !fileUpload.files || !fileUpload.files[0]) return;
    const file = fileUpload.files[0];
    const formData = new FormData();
    formData.append('photo', file);
    uploadImage(appState.auth.token, appState.lang.langType, formData).then((guid: string) => {
      this.editSuccess({photo: guid});
    });
  };

  getCardEdit = (): CardEditModel | null => {
    if(!this.state.cardForEdit) return null;
    return {...this.state.cardForEdit} as CardEditModel;
  }

  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.user,
      {user} = appState.user;
    if(!user) return this.loadingView();
    let cardId = this.getCardId();
    let isSelf = false;
    if(cardId === 0)
      if(!user.has_card || !user.personal_card) return this.noCardView();
      else cardId = user.personal_card.id;
    let card: IPersonalCardFull | null = null;
    if(user.has_card && user.personal_card && user.personal_card.id === cardId) {
      card = appState.card.card;
      isSelf = true;
      if(!card) return this.loadingView();
    } else {
      if(this.state.card && this.state.card.id === cardId)
        card = this.state.card;
      if(!card) {
        this.loadCard();
        return this.loadingView();
      }
    }
    const date = null;
    const editModal = this.state.isEdit ? 
      <CardEditorModal isKid={false} model={{...card}} onClose={this.editClose} onSuccess={this.editSuccess}></CardEditorModal> : null;
    const motherModal = this.state.isMotherEdit ?
      <CardEditorModal isKid={false} model={{date_of_birth: date, date_of_death: date, personal_card_id: card.id}} onClose={this.motherClose} onSuccess={this.motherSuccess}></CardEditorModal> : null;
    const kidModal = this.state.isKidEdit ?
      <CardEditorModal isKid={true} model={{date_of_birth: date, date_of_death: date, gender: 'M', personal_card_id: card.id}} onClose={this.kidClose} onSuccess={this.kidSuccess}></CardEditorModal> : null;
    const cardEdit = this.getCardEdit();
    const cardModal = this.state.isCardEdit && cardEdit ?
      <CardEditorModal isKid={false} model={cardEdit} onClose={this.editCardClose} onSuccess={this.editCardSuccess}></CardEditorModal> : null;
    const moderationModal = this.state.isModeration ? <ModerationModal onClose={this.moderationClose} ></ModerationModal> : null;
    return (
      <>
        <LayoutInner>
          <section className="content-user-about">
            <div className="wrap">
              <Menu />
            </div>
          </section>
          <section className="user-info">
            <div className="wrap">
              <div className="wrap-user-about">
                <div className="user-about">
                  <div className="user-photo">
                    {!!card && card.allow_edit && <input id="avatarUpload" type="file" onChange={() => this.changeAvatar(card)} />}
                    <img src={this.getAvatarUrl(card)} alt="" />
                  </div>
                  <div className="user-text-about">
                    <p className="last-name">{card.surname ? card.surname : lang.nofamily}</p>
                    <p className="first-name">{card.name ? card.name : lang.noname}</p>
                    <div className="user-date"> {card.date_of_birth ? card.date_of_birth : lang.nodob} {card.date_of_death ? ` - ${card.date_of_death}` : ''}</div>
                    <div className="btn-group">
                      {card.allow_chat && card.user_id && !isSelf && card.user_id !== user.id &&
                        <Link className="btn-send" to={`/chat/${card.user_id}`}><img src="/images/btn-icon.png" alt="" />{lang.write}</Link>
                      }
                      {card.allow_geo && card.user_id &&
                        <Link className="btn-send" to={`/geo/${cardId}`}><img src="/images/btn-marker.png" alt="" />{lang.onmap}</Link>
                      }
                    </div>
                  </div>
                </div>
                <div className="user_tree">
                  <Link to={`/geneology/${cardId}`}><img src="/images/tree-user.png" alt="" /></Link>
                  <Link to={`/geneology/${cardId}`}>{lang.tree}</Link>
                </div>
              </div>
            </div>
          </section>
          <section className="tab_user">
            <div className="container">
              <ul className="tabs-user">
                <li><a href={undefined} className={this.getTabClass('links')} onClick={() => this.setState({tab: 'links'})}>{lang.links}</a></li>
                <li><a href={undefined} className={this.getTabClass('history')} onClick={() => this.setState({tab: 'history'})}>{lang.history}</a></li>
                <li><a href={undefined} className={this.getTabClass('foto')} onClick={() => this.setState({tab: 'foto'})}>{lang.photo}</a></li>
                <li><a href={undefined} className={this.getTabClass('video')} onClick={() => this.setState({tab: 'video'})}>{lang.video}</a></li>
                <li><a href={undefined} className={this.getTabClass('contacts')} onClick={() => this.setState({tab: 'contacts'})}>{lang.contacts}</a></li>
              </ul>
              {this.getTab(card)}
            </div>
          </section>
        </LayoutInner>
        {editModal}
        {motherModal}
        {kidModal}
        {moderationModal}
        {cardModal}
      </>
    );
  }
};
