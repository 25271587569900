import React, {Component, FormEvent} from 'react';
import {observer} from 'mobx-react';
import Autosuggest, {SuggestionsFetchRequestedParams, ChangeEvent} from 'react-autosuggest';

import {LayoutInner} from '../components/LayoutInner';
import {Menu} from '../components/Menu';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {loadGenusTree, loadGenusCards} from '../services/gen.service';
import {CardItem} from '../components/CardItem';
import {IShortCard, IPersonalCardFull, IShortCardsList, IUserExt, ITooltip} from '../types/api';
import {Redirect} from 'react-router';
import {AddCardModal} from '../components/AddCardModal';
import {loadCardById, addKid, tooltips} from '../services/card.service';
import {loadUser} from '../services/user.service';
import {CardEditorModal} from '../components/CardEditorModal';
import {CardEditModel} from '../types/models';
import {ModerationModal} from '../components/ModerationModal';

interface GeneologyProps {};

interface GeneologyState {
  toUserId: number | null;
  toAddCardId: number | null;
  search: string;
  searchValues: IShortCard[];
  wasBuild: boolean;
  cards: IShortCard[];
  id: number | null;
  parentId: number | null;
  isModeration: boolean;
  card: IPersonalCardFull | null;
  tooltips: ITooltip[];
  isUser: boolean;
};

@observer
export class Geneology extends Component<GeneologyProps, GeneologyState> {
  static contextType = StoreContext;

  constructor(props: GeneologyProps) {
    super(props);
    this.state = {
      toUserId: null,
      toAddCardId: null,
      search: '',
      searchValues: [],
      wasBuild: false,
      cards: [],
      id: null,
      parentId: null,
      isModeration: false,
      card: null,
      tooltips: [],
      isUser: false
    };
  }

  onCardClick = (card: any, choosed: any) => {
    if(!card || ! choosed) return;
    const card_id = parseInt(choosed.card_id);
    if(card_id > 0) {
      if(card.user_id !== null && card.user_id > 0) this.setState({toUserId: card_id});
      else this.setState({toAddCardId: card_id});
    }
  }

  updateTree() {
    const
      appState = this.context as AppState;
    const cardId = this.getCardId();
    if(cardId === null) {
      const user = appState.user.user;
      if(user) {
        this.buildTree(user.genus_id);
      }
    } else {
      const card = this.state.card;
      if(card) {
        this.buildTree(card.genus_id, card.name, card.id);
      }
    }
  }

  load() {
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    const cardId = this.getCardId();
    if(cardId === null) {
      const user = appState.user.user;
      if(user) {
        this.buildTree(user.genus_id);
        loadGenusCards(user.genus_id).then((list: IShortCardsList) => {
          this.setState({cards: list.cards});
        });
      } else {
        loadUser(token, langType).then((user: IUserExt) => {
          this.buildTree(user.genus_id);
          loadGenusCards(user.genus_id).then((list: IShortCardsList) => {
            this.setState({cards: list.cards});
          });
        });
      }
    } else {
      loadCardById(cardId, token, langType).then((card: IPersonalCardFull) => {
        this.buildTree(card.genus_id, card.name, card.id);
        this.setState({card});
        loadGenusCards(card.genus_id).then((list: IShortCardsList) => {
          this.setState({cards: list.cards});
        });
      });
    }
  }

  updateToken() {
    const
      appState = this.context as AppState,
      token = appState.auth.token;
    if(token) {
      const tree = document.getElementById('tree') as HTMLIFrameElement;
      if(!tree) return;
      const treeWnd = tree.contentWindow as Window;
      (treeWnd as any).token = token;
    }
  }

  buildTree(genusId: number, name?: string, cardId?: number) {
    const
      appState = this.context as AppState,
      token = appState.auth.token;
    loadGenusTree(genusId).then((treeContent: string) => {
      const tree = document.getElementById('tree') as HTMLIFrameElement;
      if(!tree) return;
      const treeWnd = tree.contentWindow as Window;
      let content = treeContent;
      while(content.indexOf('https://eshejire.kz') >= 0) {
        content = content.replace('https://eshejire.kz', '');
      }
      treeWnd.document.open();
      treeWnd.document.write(content);
      treeWnd.document.close();
      (treeWnd as any).token = token;
      (treeWnd as any).onCardClick = this.onCardClick;
      (treeWnd as any).onNewClick = this.onNewClick;
      if(name && cardId) {
        (treeWnd as any).onBuild = () => {
          (treeWnd as any).searchById(name, cardId);
        };
      }
    });
  }

  openModerate = () => {
    this.setState({isModeration: true});
  }

  moderationClose = () => {
    this.setState({isModeration: false});
  }

  onNewClick = (parentId: number) => {
    this.setState({parentId});
  }

  kidClose = () => {
    this.setState({parentId: null});
  }

  kidSuccess = (card: CardEditModel) => {
    this.setState({parentId: null});
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    addKid(token, langType, card).then(() => {
      this.openModerate();
    });
  }

  componentDidMount() {
    this.load();
    this.setState({id: this.getCardId()});
  }

  componentDidUpdate() {
    const
      appState = this.context as AppState,
      user = appState.user.user;
    const id = this.getCardId();
    if(this.state.id !== id) {
      this.load();
      this.setState({id});
    } else {
      if(!this.state.isUser) this.updateTree();
      if(user && !this.state.isUser) this.setState({isUser: true});
      if(!user && this.state.isUser) this.setState({isUser: false});
    }
    this.updateToken();
  }

  toggleEdit() {
    const tree = document.getElementById('tree') as HTMLIFrameElement;
    if(!tree) return;
    const treeWnd = tree.contentWindow as any;
    if(treeWnd.toggleShow) treeWnd.toggleShow();
  }

  onAddClose = () => {
    this.setState({toAddCardId: null});
  }

  onAddSuccess = (cardId: number) => {
    this.setState({toAddCardId: null});
  }

  showCard = (name: string, id: number) => {
    const tree = document.getElementById('tree') as HTMLIFrameElement;
    if(!tree) return;
    const treeWnd = tree.contentWindow as any;
    if(treeWnd.search) treeWnd.searchById(name, id);
  }

  getCardId(): number | null {
    let cardId: number | null = null;
    const anyProps = this.props as any;
    if(
      anyProps.match &&
      anyProps.match.params &&
      anyProps.match.params.cardId) {
        cardId = parseInt(anyProps.match.params.cardId);
      if(isNaN(cardId)) cardId = null;
    }
    return cardId;
  }

  loadingView() {
    return (
      <LayoutInner>
        <section className="content-article">
          <div className="wrap">
            <Menu />
            <div className="main-article-content">
              <div className="spinner2"></div>
            </div>
          </div>
        </section>
      </LayoutInner>
    );
  }

  searchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const query = this.state.search;
    if(query.length < 4) return;
    const tree = document.getElementById('tree') as HTMLIFrameElement;
    if(!tree) return;
    const treeWnd = tree.contentWindow as any;
    if(treeWnd.search) treeWnd.search(query);
  }

  searchChange = (event: FormEvent<HTMLInputElement>, params: any) => {
    const resp = params as ChangeEvent;
    if(resp && resp.newValue) {
      this.setState({search: resp.newValue});
    } else {
      this.setState({search: event.currentTarget.value});
    }
  }

  tooltipsFetch = (event: SuggestionsFetchRequestedParams) => {
    const
      appState = this.context as AppState,
      langType = appState.lang.langType,
      user = appState.user.user,
      {card} = this.state,
      search = event.value;
    if(!user) return;
    let genusId: number = user.genus_id;
    if(card) genusId = card.genus_id;
    if(search && search.length > 0)
      tooltips(langType, genusId, search)
        .then((tooltips: ITooltip[]) => {
          this.setState({tooltips});
        });
  }

  tooltipsClear = () => {
    this.setState({tooltips: []});
  }

  render() {
    if(this.state.toUserId !== null) return <Redirect to={`/user/${this.state.toUserId}`}></Redirect>
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.geneology;
    const modal = this.state.toAddCardId !== null ? (
      <AddCardModal cardId={this.state.toAddCardId} onClose={this.onAddClose} onSuccess={this.onAddSuccess}></AddCardModal>
    ) : null;
    const user = appState.user.user;
    const editorModal = this.state.parentId !== null ? (
      <CardEditorModal isKid={true} model={{date_of_birth: null, date_of_death: null, gender: 'M', personal_card_id: this.state.parentId}} onClose={this.kidClose} onSuccess={this.kidSuccess}></CardEditorModal>
    ) : null;
    const moderationModal = this.state.isModeration ? <ModerationModal onClose={this.moderationClose} ></ModerationModal> : null;
    if(!user) return this.loadingView();
    if(!user.genus) return <Redirect to={`/tree}`}></Redirect>
    const inputProps = {
      placeholder: lang.search,
      value: this.state.search || '',
      onChange: this.searchChange
    }, tips = this.state.tooltips;
    return (
      <React.Fragment>
        <LayoutInner>
          <section className="content-article">
            <div className="wrap">
              <Menu />
              <div className="main-article-content">
                <h1 className="news-title">{lang.tree} - {user.genus.name}</h1>
                <form className="search" onSubmit={this.searchSubmit}>
                  <Autosuggest
                    suggestions={tips}
                    onSuggestionsFetchRequested={this.tooltipsFetch}
                    onSuggestionsClearRequested={this.tooltipsClear}
                    getSuggestionValue={(value: ITooltip) => value.name}
                    renderSuggestion={(value: ITooltip) => <div>{value.name}</div>}
                    inputProps={inputProps}
                  />
                  <input type="submit" value="" className="submit" />
                </form>
                {/* <input type="search" placeholder={lang.search} className="input" onChange={this.searchChange} /> */}
                <div className="content-tree-rod">
                  <iframe id="tree" width="100%" height="500px"></iframe>
                </div>
              </div>
              <h3 className="list-create-pages">{lang.list}</h3>
              <ul>
                {this.state.cards.map((card: IShortCard) => <CardItem key={card.id} card={card} showCard={(name) => this.showCard(name, card.id)}></CardItem>)}
              </ul>
              <div className="btn-wrap-tree">
                <a  className="btn-add-genus btn-tree" onClick={() => this.toggleEdit()}> + {lang.add} </a>
              </div>
            </div>
          </section>
        </LayoutInner>
        {modal}
        {editorModal}
        {moderationModal}
      </React.Fragment>
    );
  }
};
