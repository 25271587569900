import React, {Component} from 'react';

import {IPersonalCardFull} from '../types/api';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';

interface UserContactsProps {
  card: IPersonalCardFull | null;
  onEdit: () => void;
  onMyPage: () => void;
};

export class UserContacts extends Component<UserContactsProps> {
  static contextType = StoreContext;

  get userName(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    if(this.props.card && this.props.card.name) return this.props.card.name;
    return '';
  }

  get userSecondName(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    return '';
  }

  get userDateOfBirth(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    if(this.props.card && this.props.card.date_of_birth) return this.props.card.date_of_birth;
    return lang.nodob;
  }

  get isDead(): boolean {
    if(!this.props.card) return false;
    return !!this.props.card.date_of_death;
  }

  get userDateOfDeath(): string {
    return '';
  }

  get userPhone(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    if(this.props.card && this.props.card.phone) return `+${this.props.card.phone.toString()}`;
    return lang.nophone;
  }

  get userEmail(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    if(this.props.card && this.props.card.email) return this.props.card.email;
    return lang.noemail;
  }

  get userAddress(): string {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user;
    if(this.props.card && this.props.card.address) return this.props.card.address;
    return lang.noaddress;
  }

  get canEdit(): boolean {
    if(!this.props.card) return false;
    return this.props.card.allow_edit;
  }
  
  render() {
    const appState = this.context as AppState;
    const lang = appState.lang.lang.user.contactsTab;
    return (
      <div id="fifth">
        <div className="wrap user-contact-wrap">
          <div className="user-contact">
            <div className="item-contact">
              <p className="label-contact">{lang.fio}</p>
              <p>{this.userName} {this.userSecondName}</p>
            </div>
            <div className="item-contact">
              <p className="label-contact">{lang.dob}</p>
              <p>{this.userDateOfBirth}</p>
            </div>
            {this.isDead ? (
              <div className="item-contact">
                <p className="label-contact">{lang.dod}</p>
                <p>{this.userDateOfDeath}</p>
              </div>
            ) : undefined}
            <div className="item-contact">
              <p className="label-contact">{lang.phone}</p>
              <p>{this.userPhone}</p>
            </div>
            <div className="item-contact">
              <p className="label-contact">{lang.email}</p>
              <p>{this.userEmail}</p>
            </div>
            <div className="item-contact">
              <p className="label-contact">{lang.address}</p>
              <p>{this.userAddress}</p>
            </div>
          </div>
          <div className="user-contact-tools">
            {this.canEdit &&
              <>
                {/* <a className="btn-add-genus" onClick={this.props.onMyPage}>{lang.page}</a> */}
                <a className="edit-contact" onClick={this.props.onEdit}><img src="/images/replace.png" alt="" /></a>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
};
