import React, {Component} from 'react';

import {IPersonalCardFull, IMedia} from '../types/api';
import {uploadMedia, removeMedia} from '../services/card.service';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {ModalBack} from './ModalBack';

interface UserFotoProps {
  card: IPersonalCardFull | null;
  onModerate: () => void;
};

interface UserFotoState {
  img: IMedia | null;
  remove: IMedia | null;
  isRemove: boolean;
};

export class UserFoto extends Component<UserFotoProps, UserFotoState> {
  static contextType = StoreContext;

  constructor(props: UserFotoProps) {
    super(props);
    this.state = {img: null, remove: null, isRemove: false};
  }

  removeImage = (image: IMedia) => {
    this.setState({remove: image, isRemove: true});
  }

  closeRemoveModal = () => {
    this.setState({remove: null, isRemove: false});
  }

  successRemoveModal = () => {
    const remove: IMedia | null = this.state.remove;
    if(!remove) return;
    const
      appState = this.context as AppState,
      langType = appState.lang.langType;
    removeMedia(appState.auth.token, remove.id, langType).then(() => {
      this.setState({remove: null, isRemove: false});
      this.props.onModerate();
    });
  }

  openSlide = (img: IMedia) => {
    this.setState({img});
  }

  closeSlide = () => {
    this.setState({img: null});
  }

  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.user.fotoTab;

    const uploadFoto = () => {
      if(!this.props.card) return;
      const fileUpload = document.getElementById('fotoUpload') as HTMLInputElement;
      if(!fileUpload || !fileUpload.files || !fileUpload.files[0]) return;
      const file = fileUpload.files[0];
      const formData = new FormData();
      formData.append('media', file);
      uploadMedia(appState.auth.token, this.props.card.id, appState.lang.langType, formData).then(this.props.onModerate);
    };

    const exists = this.props.card && this.props.card.photos && this.props.card.photos.length > 0 ? true : false;

    const photos = () => {
      if(!this.props.card || !this.props.card.photos || this.props.card.photos.length == 0) return null;
      const edit = this.props.card && this.props.card.allow_edit;
      return this.props.card.photos.map((photo: IMedia) => {
        return (
          <div className="photo-bank-wrap" key={photo.id} onClick={() => this.openSlide(photo)}>
            <img src={photo.preview_url} alt="" />
            {edit && <div className="photo-bank-wrap-delete" onClick={(event) => {event.stopPropagation(); this.removeImage(photo);}}></div>}
          </div>
        );
      });
    };

    const addPhoto = () => {
      if(!this.props.card || !this.props.card.allow_edit) return null;
      return (
        <div className="user-contact-tools">
          <a className="btn-add-genus">
            + {lang.add}
            <input id="fotoUpload" type="file" onChange={uploadFoto} />
          </a>
        </div>
      );
    };

    const slider = () => {
      if(!this.state.img) return null;
      return (
        <ModalBack onClick={this.closeSlide}>
          <div className="photo-slide-close-btn" onClick={this.closeSlide}></div>
          <div className="photo-slide" style={{backgroundImage: `url(${this.state.img.file_url})`}}></div>
        </ModalBack>
      );
    };

    const removeModal = () => {
      if(!this.state.isRemove) return null;
      return (
        <ModalBack onClick={this.closeRemoveModal}>
          <div className="add-card-modal" onClick={(event: any) => event.stopPropagation()}>
            <div className="modal-close-btn" onClick={this.closeRemoveModal}></div>
            <div className="add-card-title">{lang.remove}</div>
            <div className="add-card-ok-button" onClick={this.successRemoveModal}>{lang.removeTxt}</div>
          </div>
        </ModalBack>
      );
    };
    
    return (
      <>
        <div id="third">
          <div className="wrap user-contact-wrap">
            <div className="photo-bank">
              {!exists && <h1>{lang.nofoto}</h1>}
              {photos()}
            </div>
            {addPhoto()}
          </div>
          {removeModal()}
          {slider()}
        </div>
      </>
    );
  }
};
