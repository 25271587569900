import {observable, action} from 'mobx';

import {loadNews, searchNews} from '../services/rodnews.service';
import {ApiState} from './api.state';
import {INewsItem} from '../types/api';
import {LangType} from '../types/lang';

export class RodnewsState extends ApiState {
  @observable news: INewsItem[] = [];

  @action toDefault() {
    super.toDefault();
    this.news = [];
  }

  @action load(token: string, langType: LangType, query: string = ''): Promise<INewsItem[]> {
    this.isLoading = true;
    this.news = [];
    this.error = '';
    let result: Promise<INewsItem[]>;
    if(query)
      result = searchNews(token, langType, query);
    else
      result = loadNews(token, langType);
    result
      .then(this.loadSuccess)
      .catch(this.loadFailed);
    return result;
  }

  @action.bound private loadSuccess(news: INewsItem[]) {
    if(!this.isLoading) throw '';
    this.isLoading = false;
    this.news = news;
  }

  @action.bound private loadFailed(errorData: any) {
    this.isLoading = false;
    if(typeof errorData === 'string') this.error = errorData;
    else this.error = 'News error';
  }
}
