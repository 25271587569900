import React, {FC} from 'react';
import {observer} from 'mobx-react';

import {ModalBack} from './ModalBack';
import {useState} from './Store';
import {IAuthor} from '../types/api';

interface SetGenusModalProps {
  genus: number;
  author: IAuthor;
  onClose: () => void;
  onSuccess: (genus: number) => void;
};

export const SetGenusModal: FC<SetGenusModalProps> = observer(
  (props: SetGenusModalProps) => {
    const appState = useState();
    return (
      <ModalBack onClick={() => props.onClose()}>
        <div className="set-genus-modal" onClick={(event: any) => event.stopPropagation()}>
          <div className="modal-close-btn" onClick={props.onClose}></div>
          <div className="genus-source-title">{appState.lang.lang.tree.source}</div>
          <div className="genus-source-author">{props.author.author}</div>
          <div className="genus-source-book">{props.author.source}</div>
          <div className="genus-source-place">{props.author.description}</div>
          <div className="genus-source-isbn">ISBN: {props.author.isbn}</div>
          <div className="genus-ok-button" onClick={() => props.onSuccess(props.genus)}>{appState.lang.lang.tree.ok}</div>
        </div>
      </ModalBack>
    )
  }
);
