import React, {createContext, PropsWithChildren, FC} from 'react';
import {useLocalStore} from 'mobx-react';

import {AppState, createState} from '../state/app.state';

export const StoreContext = createContext<AppState | null>(null);

type StoreProviderProps = PropsWithChildren<any>;

export const StoreProvider: FC<StoreProviderProps> = ({children}: StoreProviderProps) => {
  const store = useLocalStore(createState);
  return (<StoreContext.Provider value={store}>{children}</StoreContext.Provider>);
};

export const useState = (): AppState => {
  const state = React.useContext(StoreContext);
  if (!state) {
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return state;
};
