import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {IShortCard} from '../types/api';

interface CardItemProps {
  card: IShortCard;
  showCard: (name: string) => void;
};

interface CardItemState {};

export class CardItem extends Component<CardItemProps, CardItemState> {
  
  render() {
    return (
      <li className="item-list-tree">
        <div className="left-part-list">
          <div className="img-wrap-list">
            <img src={this.props.card.photo_url ? this.props.card.photo_url : '/images/no-photo.png'} alt="" />
          </div>
          <p>{this.props.card.name}</p>
        </div>
        <div className="right-part-list">
          <a onClick={() => this.props.showCard(this.props.card.name)}><img src="/images/easy.png" alt="" /></a>
          <Link to={`/user/${this.props.card.id}`}><img src="/images/icon-page.png" alt="" /></Link>
        </div>
      </li>
    );
  }
}
