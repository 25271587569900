import {Lang} from '../types/lang';

export const kz: Lang = {
  signin: {
    phone: 'Телефон нөмірі',
    password: 'Пароль',
    forget: 'Құпия сөзді ұмыттыңыз ба?',
    enter: 'КІРІС',
    error: 'Мұндай логин / пароль жоқ'
  },
  registration: {
    phone: 'Телефон нөмірі',
    password: 'Құпиясөз',
    registrate: 'ТІРКЕЛУ',
    existError: 'Мұндай логині бар пайдаланушы бұрыннан бар',
    lengthError: 'Құпиясөздің ең қысқа ұзындығы 4 таңба',
    agreementOne: 'Нажимая "Зарегистрироваться вы принимаете"',
    agreementTwo: 'пользовательское соглашение'
  },
  auth: {
    sigin: 'Кіру',
    registration: 'Тіркелу'
  },
  main: {
    tree: 'Ру шежіресін ашу',
    text: 'Тегінің ежелгі тамырын Иван Грозныйдың кезінде Ежелгі Ресей тұрғындарының кітабынан табуға болады. Патша тек мақтау немесе мадақтау кезінде туыстарына берілетін княздық және ең жақсы есімдердің арнайы тізімін жүргізді. Осылайша, бұл тегі жеке қайталанбас құндылықты сақтап қалды және ерекше болып табылады.',
    download: 'Қосымшаны жүктеп алыңыз'
  },
  menu: {
    news: 'Жаңалықтар',
    history: 'Тарих',
    tree: 'Шежіре',
    geo: 'Геолокация',
    chat: 'Чат',
    faq: 'Сұрақ-жауап',
    geneology: 'Шежіре',
    context: {
      personal: 'Жеке кабинет',
      tree: 'Руды өзгерту',
      settings: 'Баптау',
      exit: 'Шығу',
      news: 'eShejire жаңалықтары'
    }
  },
  footer: {
    write: 'БІЗГЕ ЖАЗУ'
  },
  news: {
    title: 'Жаңалықтар'
  },
  newsitem: {
    full: 'Толық оқу'
  },
  article: {
    allnews: 'Барлық жаңалықтар',
    share: 'Бөлісу:',
    allhistory: 'Барлық тарихтар'
  },
  user: {
    nocard: 'Парақша жасалған жоқ',
    write: 'ЖАЗУ',
    onmap: 'КАРТАДА',
    tree: 'Шежіре',
    links: 'Туысқандық байланыстар',
    history: 'Рудың тарихы',
    photo: 'Фото',
    video: 'Видео',
    contacts: 'Контактілер',
    contactsTab: {
      fio: 'ТАӘ:',
      dob: 'Туған күні:',
      dod: 'Қайтыс болған күні:',
      phone: 'Телефон:',
      email: 'Email:',
      address: 'Мекенжайы:',
      page: 'БҰЛ МЕНІҢ ПАРАҚШАМ'
    },
    noname: 'есім орнатылған жоқ',
    nofamily: '',
    nodob: 'туған күні орнатылған жоқ',
    nophone: 'телефон орнатылған жоқ',
    noemail: 'email орнатылған жоқ',
    noaddress: 'мекенжай орнатылған жоқ',
    linksTab: {
      father: 'әке',
      mother: 'шеше',
      son: 'ұл',
      dougter: 'қыз',
      addMother: 'ШЕШЕНІ ҚОСУ',
      addKid: 'ҰЛ БАЛАНЫ ҚОСУ'
    },
    historyTab: {
      nohistory: 'Тарих жоқ',
      add: 'Тарихты қосу',
      titleRu: 'Заголовок истории (на русском)',
      textRu: 'Текст (на русском)',
      titleKz: 'Заголовок истории (на казахском)',
      textKz: 'Текст (на казахском)',
      remove: 'Жою',
      save: 'Сақтау'
    },
    fotoTab: {
      add: 'ФОТО ҚОСУ',
      remove: 'Суретті өшіру керек пе?',
      removeTxt: 'Жою',
      nofoto: 'Фотолар жоқ'
    },
    videoTab: {
      add: 'ВИДЕО ҚОСУ',
      remove: 'Видеоны өшіру керек пе?',
      removeTxt: 'Жою',
      novideo: 'Видео жоқ'
    }
  },
  tree: {
    source: 'Дереккөз:',
    ok: 'Өту',
    choose: 'Руды таңдау',
    list: 'Қосылған рулардың тізімі',
    search: 'Әкесінің аты + ұлдың атын енгізіңіз',
    cancel: 'Закрыть',
    emptyMsg1: 'К сожалению, информации по роду',
    emptyMsg2: 'пока нет. Если у вас есть информация, или вы знаете, кто ею владеет, пожалуйста, напишите нам',
    demo: 'Посмотреть пример заполненного рода'
  },
  geneology: {
    add: 'ҚОСУ',
    tree: 'Шежіре',
    list: 'Жасалған парақшаның тізімі',
    search: 'Әкесінің аты + ұлдың атын енгізіңіз'
  },
  editor: {
    son: 'Ұл',
    dougter: 'Қыз',
    surname: 'Тегі',
    name: 'Аты',
    middleName: 'Әкесінің аты',
    dob: 'Туған күні',
    dod: 'Қайтыс болған күні',
    phone: 'Телефон',
    email: 'e-mail',
    address: 'Мекенжайы',
    image: 'Суретті жүктеу',
    save: 'Сақтау',
  },
  addCardModal: {
    msg: 'Парақша жасалған жоқ.',
    add: 'Жасау'
  },
  genus: {
    inwork: 'Әзірлеуде'
  },
  moderationModal: {
    request: 'Сұратым модерацияға жіберілді',
    close: 'Жабу'
  },
  recoverModal: {
    sendCode: 'Код жіберу',
    phone: 'Телефон нөмірі',
    phoneError: 'Формат номера: +###########',
    code: 'Код',
    codeError: 'Код 4 таңбадан құрылу керек',
    password: 'Құпиясөз',
    passwordError: 'Құпиясөз кемінде 4 таңбадан құрылу керек'
  },
  textMsg: {
    read: 'Қаралды'
  },
  settingsModal: {
    msg: 'Хабарлама қабылдау',
    geo: 'Менің орналасқан жерімді көрсету',
    fem: 'Отображать дочерей',
    save: 'Сақтау'
  },
  chat: {
    title: 'Чат'
  },
  geo: {
    title: 'Геолокация'
  },
  history: {
    title: 'Тарих'
  },
  agreement: {
    close: 'Принять'
  },
  faqItems: [{
    title: 'Қосымшаны қалай қолдану керек?',
    text: `
    Бейнеролик сіздердің көптеген сұрақтарыңызға жауап береді<br /> <br />
    <iframe width="100%" height="500px" src="https://www.youtube.com/embed/HdEElPHT834" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    `
  }, {
    title: 'Парақшаны не үшін құру керек?',
    text: `
    Әрқайсымыздың өміріміздегі ерекше және қызықты жетістіктер мен сәттер болады және біз олармен мақтана аламыз. Осындай жетістіктер сіздің әкеңіз, атаңыз,
    үлкен аталарларыңызда да бар, бірақ сіз оларды білмесеңіз, олар туралы сұрамаған шығарсыз. Егер сіз бұл туралы жазып алмаған болсаңыз бұл туралы 
    балаларыңыз, немерелеріңіз және болашақ ұрпақтарыңыз білмейтін болады. Сондықтан, ата-бабаларыңыздың парақшаларын жасай отырып, олардың өмірлік әңгімелерін, іс-әрекеттері
    мен жетістіктерін сақтай отырсаңыздар болашақта сіздің ұрпақтарыңыз мақтан тұтады!
    `
  }, {
    title: 'Қалай төлеуге болады?',
    text: `
    Сіздің Google аккаунтыңызға тіркелген кез келген банк картасы немесе Билайн нөмірі арқылы төлемді жүзеге асыруға болады.
    `
  }, {
    title: 'Шежіреде өзіңізді қалай тауып алуға болады?',
    text: `
    Іздеу жолағына өзіңіздің есіміңізді жазыңыз, есіміңізді жазар кезде Шежіредегі есімдердің түрлі нұсқалары бар ескертулерге назар аударыңыз, 
егер табылған есімдер дұрыс болмаған болса, онда астына немесе үстіне жылжыту тетігін қолданыңыз. 
Өзіңізді жылдам тауып алғыңыз келсе әкеңіздің есімін бос орын (пробел) өзіңіздің есіміңізді жазыңыз. 
    `
  }, {
    title: 'Жаңалықты қалай қосуға болады?',
    text: `
    Шежіреге қосымша толықтыруларды енгізу үшін info@eshejire.kz поштасына немесе +7 777 484 6323 ватсап нөміріне жазыңыз.
    `
  }, {
    title: 'Ру тарихын қалай қосуға болады?',
    text: `
    Ру тарихын қосу үшін үшін info@eshejire.kz поштасына немесе +7 777 484 6323 ватсап нөміріне жазыңыз.
    `
  }, {
    title: 'Неге фотосуреттер көмескі көрінеді?',
    text: `
    Фотосуреттерді жақсы сапада көру үшін сізге осы руды "Оқу және тілдесу" жазылымына жазылған жөн.
    `
  }, {
    title: 'Есімді қалай қосуға болады?',
    text: `
    Шежіреге есімді қосу үшін оң жақ бұрыштың астындағы + басу керек, онда рудың шежіресі шығады, сол жерге есімді қосуға болады. Назар аударыңыз! Есімді қосу үшін 
    ағымдағы жылға "Сақтау" немесе  "Оқу және тілдесу" жазылымдары болған кезде ғана жүзеге асырылады.
    `
  }, {
    title: 'Геолокацияны қалай өшіруге болады?',
    text: `
    Геолокацияны қосымшаның жөндеу мәзірінде өшіруге болады. Ол үшін бүйірлік тақтаны ашыңыз және тісті доңғалақ белгішесін басыңыз.
    `
  }, {
    title: 'Чатты қалай өшіруге болады?',
    text: `
    Чатты қосымшаның жөндеу мәзірінде өшіруге болады. Ол үшін бүйірлік тақтаны ашыңыз және тісті доңғалақ белгішесін басыңыз. 
    `
  }, {
    title: 'Басқа руға қалай өтуге болады?',
    text: `
    Басқа руға өту үшін қосымшаның жөндеу мәзірін ашу керек. Онда бүйірлік тақтаны ашыңыз және тісті доңғалақ белгішесін басыңыз.
    `
  }, {
    title: 'Мен қате таптым',
    text: `
    Егер Сіз қате тауып алсаңыз info@eshejire.kz  поштасына немесе +7 777 484 6323 ватсап нөміріне жазыңыз.
    `
  }]
};
