import axios, { AxiosResponse } from 'axios';

import {IUserExt} from '../types/api';
import {IUpdateUser} from '../types/models';
import {LangType} from '../types/lang';
import {getLang} from './utils';

interface LoadUserResult {
  user?: IUserExt;
  message?: string;
};

export const loadUser = async (token: string, langType: LangType): Promise<IUserExt> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.get<LoadUserResult>('/api/v1.0/user', {headers})
    .then((response: AxiosResponse<LoadUserResult>) => {
      if(response.data && response.data.user) return response.data.user;
      if(response.data && response.data.message)
        throw Error(`Load User error: ${response.data.message}`);
      throw Error(`Load User error: ${response.statusText}`);
    });
};

interface UserUpdateResult {
  success?: boolean;
  message?: string;
  error?: string;
};

export const updateUser = async (token: string, langType: LangType, user: IUpdateUser): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.put<UserUpdateResult>('/api/v1.0/user', user, {headers})
    .then((response: AxiosResponse<UserUpdateResult>) => {
      if(response.data && response.data.success) return;
      if(response.data && response.data.message)
        throw Error(`User Update error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`User Update error: ${response.data.error}`);
      throw Error(`User Update error: ${response.statusText}`);
    });
};
