import React, {Component} from 'react';
import {observer} from 'mobx-react';

import {Signin} from './Signin';
import {Registration} from './Registration';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';

export type TabState = 'signin' | 'registration';

export interface AuthState {
  tab: TabState;
};

interface AuthProps {};

@observer
export class Auth extends Component<AuthProps, AuthState> {
  static contextType = StoreContext;

  constructor(props: AuthProps) {
    super(props);
    this.state = {
      tab: 'signin'
    };
  }

  getTabClass(tabState: TabState): string {
    if(this.state.tab === tabState) return 'active';
    return '';
  }

  get isSignin(): boolean {
    return this.state.tab === 'signin';
  }

  render() {
    const appState = this.context as AppState;
    const {auth} = appState.lang.lang;
    return (
      <form className="main-form" action="#">
        <div className="tabs">
          <ul>
            <li className={this.getTabClass('signin')} onClick={() => this.setState({tab: 'signin'})}>{auth.sigin}</li>
            <li className={this.getTabClass('registration')} onClick={() => this.setState({tab: 'registration'})}>{auth.registration}</li>
          </ul>
          <div>
            {this.isSignin ? (
              <Signin />
            ): (
              <Registration />
            )}
          </div>
        </div>
      </form>
    );
  }
};
