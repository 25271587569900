import {observable, action} from 'mobx';

import {loadGenusList} from '../services/gen.service';
import {ApiState} from './api.state';
import {IGenusList} from '../types/api';
import {LangType} from '../types/lang';

export class TreeState extends ApiState {
  @observable genusList: IGenusList = {
    demo: null,
    zhuzes: []
  } as IGenusList;
  @observable search: string = '';
  @observable select: string = '';

  @action setSearch(value: string) {
    this.search = value;
    this.select = '';
  }

  @action setSelect(value: string) {
    this.select = value;
    this.search = '';
  }

  @action toDefault() {
    super.toDefault();
    this.genusList = {
      demo: null,
      zhuzes: []
    } as IGenusList;
  }

  @action loadGenusList(langType: LangType): Promise<IGenusList> {
    this.toDefault();
    this.isLoading = true;
    const result = loadGenusList(langType);
    result
      .then(this.loadSuccess)
      .catch(this.loadFailed);
    return result;
  }

  @action.bound private loadSuccess(genusList: IGenusList) {
    if(!this.isLoading) throw '';
    this.isLoading = false;
    this.genusList = genusList;
  }

  @action.bound private loadFailed(errorData: any) {
    this.isLoading = false;
    if(typeof errorData === 'string') this.error = errorData;
    else this.error = 'Load Genus List error';
  }
}
