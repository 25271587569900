import {Lang} from '../types/lang';

export const ru: Lang = {
  signin: {
    phone: 'Номер телефона',
    password: 'Пароль',
    forget: 'Забыли пароль?',
    enter: 'ВХОД',
    error: 'Нет такой пары логин / пароль'
  },
  registration: {
    phone: 'Номер телефона',
    password: 'Пароль',
    registrate: 'ЗАРЕГИСТРИРОВАТЬСЯ',
    existError: 'Пользователь с таким логином уже существует',
    lengthError: 'Минимальная длина пароля 4 знака',
    agreementOne: 'Нажимая "Зарегистрироваться" вы принимаете',
    agreementTwo: 'пользовательское соглашение'
  },
  auth: {
    sigin: 'Вход',
    registration: 'Регистрация'
  },
  main: {
    tree: 'Ваше родовое дерево',
    text: 'Древние корни фамилии можно найти в книге жителей Древней Руси в пору царствования Ивана Грозного. У царя хранился особенный список княжеских и лучших фамилий, которые давались близким только в случае похвалы или поощрения. Тем самым эта фамилия сохранила личное неповторимое значение и является исключительной.',
    download: 'Скачивайте приложения'
  },
  menu: {
    news: 'Новости',
    history: 'История',
    tree: 'Дерево',
    geo: 'Геолокация',
    chat: 'Чат',
    faq: 'Вопрос-ответ',
    geneology: 'Дерево',
    context: {
      personal: 'Личная страница',
      tree: 'Изменить род',
      settings: 'Настройки',
      exit: 'Выход',
      news: 'Новости eShejire'
    }
  },
  footer: {
    write: 'НАПИСАТЬ НАМ'
  },
  news: {
    title: 'Новости'
  },
  newsitem: {
    full: 'Читать полностью'
  },
  article: {
    allnews: 'Все новости',
    share: 'Поделиться:',
    allhistory: 'Все истории'
  },
  user: {
    nocard: 'Страница не заведена ',
    write: 'НАПИСАТЬ',
    onmap: 'НА КАРТЕ',
    tree: 'Дерево рода',
    links: 'Родственные связи',
    history: 'История рода',
    photo: 'Фото',
    video: 'Видео',
    contacts: 'Контакты',
    contactsTab: {
      fio: 'ФИО:',
      dob: 'Дата рождения:',
      dod: 'Дата смерти:',
      phone: 'Телефон:',
      email: 'Email:',
      address: 'Адрес:',
      page: 'ЭТО МОЯ СТРАНИЦА'
    },
    noname: 'имя не задано',
    nofamily: '',
    nodob: 'день рождения не задан',
    nophone: 'телефон не задан',
    noemail: 'email не задан',
    noaddress: 'адресс не задан',
    linksTab: {
      father: 'отец',
      mother: 'мать',
      son: 'сын',
      dougter: 'дочь',
      addMother: 'ДОБАВИТЬ МАТЬ',
      addKid: 'ДОБАВИТЬ РЕБЁНКА'
    },
    historyTab: {
      nohistory: 'Нет ни одной истории',
      add: 'Добавить историю',
      titleRu: 'Заголовок истории (на русском)',
      textRu: 'Текст (на русском)',
      titleKz: 'Заголовок истории (на казахском)',
      textKz: 'Текст (на казахском)',
      remove: 'Удалить',
      save: 'Сохранить'
    },
    fotoTab: {
      add: 'ДОБАВИТЬ ФОТО',
      remove: 'Удалить изображение?',
      removeTxt: 'Удалить',
      nofoto: 'Нет ни одной фотографии'
    },
    videoTab: {
      add: 'ДОБАВИТЬ ВИДЕО',
      remove: 'Удалить видео?',
      removeTxt: 'Удалить',
      novideo: 'Нет ни одного видео'
    }
  },
  tree: {
    source: 'Источник:',
    ok: 'Перейти',
    choose: 'Выбор рода',
    list: 'Список добавленных родов',
    search: 'Введите ваше имя или имя отца+сына',
    cancel: 'Закрыть',
    emptyMsg1: 'К сожалению, информации по роду',
    emptyMsg2: 'пока нет. Если у вас есть информация, или вы знаете, кто ею владеет, пожалуйста, напишите нам',
    demo: 'Посмотреть пример заполненного рода'
  },
  geneology: {
    add: 'ДОБАВИТЬ',
    tree: 'Дерево рода',
    list: 'Список созданных страниц',
    search: 'Введите ваше имя или имя отца+сына'
  },
  editor: {
    son: 'Сын',
    dougter: 'Дочь',
    surname: 'Фамилия',
    name: 'Имя',
    middleName: 'Отчество',
    dob: 'Дата рождения',
    dod: 'Дата смерти',
    phone: 'Телефон',
    email: 'e-mail',
    address: 'Адрес',
    image: 'Загрузить изображение',
    save: 'Сохранить',
  },
  addCardModal: {
    msg: 'Страница не создана.',
    add: 'Создать'
  },
  genus: {
    inwork: 'В работе'
  },
  moderationModal: {
    request: 'Запрос отправлен на модерацию',
    close: 'Закрыть'
  },
  recoverModal: {
    sendCode: 'Отправить код',
    phone: 'Номер телефона',
    phoneError: 'Формат номера: +###########',
    code: 'Код',
    codeError: 'Код должен состоять из 4 знаков',
    password: 'Пароль',
    passwordError: 'Пароль должен состоять минимум из 4 знаков'
  },
  textMsg: {
    read: 'Просмотренно'
  },
  settingsModal: {
    msg: 'Принимать сообщения',
    geo: 'Передавать геоданные',
    fem: 'Отображать дочерей',
    save: 'Сохранить'
  },
  chat: {
    title: 'Чат'
  },
  geo: {
    title: 'Геолокация'
  },
  history: {
    title: 'История'
  },
  agreement: {
    close: 'Принять'
  },
  faqItems: [{
    title: 'Как пользоваться приложением?',
    text: `
      Видеоролик ответит на большую часть ваших вопросов. <br /> <br />
      <iframe width="100%" height="500px" src="https://www.youtube.com/embed/tK53KxIR6To" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    `
  }, {
    title: 'Зачем создавать страницы?',
    text: `
      У каждого из нас уникальная и интересная история жизни, достижения, моменты и поступки, которыми мы гордимся. Они есть у вас, у вашего отца, дедушки, прадедушки, а если вы о них не знаете, возможно вы не спрашивали о них. А если вы о них не спрашивали и не записали их, то о них не узнают и ваши дети, внуки и будущие поколения. Именно поэтому нужно создавать страницы ваших предков и сохранять их истории жизни, поступки и достижения, которыми будут гордиться ваши потомки!
    `
  }, {
    title: 'Как оплатить?',
    text: `
      Оплатить можно с помощью платежной карты любого банка или с баланса номера Билайн, подключенных к вашему Google аккаунту.
    `
  }, {
    title: 'Как найти себя в Шежире?',
    text: `
    Напишите ваше имя в строку поиска, при начале ввода имени вы можете увидеть подсказки, какие имена и варианты их написание уже есть в Шежире, если первый найденный вариант не верный, вы можете использовать стрелки вверх и вниз для поиска следующего варианта. Для более точного и быстрого поиска вы можете указать сначала имя вашего отца, затем через пробел ваше имя.
    `
  }, {
    title: 'Как добавить новость?',
    text: `
      Для добавления новости рода, пожалуйста, напишите нам на почту info@eshejire.kz или ватсап +7 777 484 6323
    `
  }, {
    title: 'Почему фотографии размытые?',
    text: `
      Чтобы фото стали четкими, вам необходимо приобрести подписку "Читай и Общайся" в этом роду.
    `
  }, {
    title: 'Как добавить имя?',
    text: `
      Чтобы добавить новое имя в шежире, нажмите на + в нижнем правом углу экрана, после этого в древе рода появится иконка, позволяющая добавить новое имя. Обратите внимание, добавление имени доступно только при наличии подписки "Сохраняй" или "Читай и Общайся" в текущем роду.
    `
  }, {
    title: 'Как отключить геолокацию?',
    text: `
      Отключить геолокацию можно в настройках приложения, откройте боковое меню и нажмите на иконку шестеренки.
    `
  }, {
    title: 'Как оплатить?',
    text: `
      Оплатить можно с помощью платежной карты любого банка или с баланса номера Билайн, подключенных к вашему Google аккаунту.
    `
  }, {
    title: 'Как отключить чат?',
    text: `
      Отключить чат можно в настройках приложения, откройте боковое меню и нажмите на иконку шестеренки.
    `
  }, {
    title: 'Как перейти в другой род?',
    text: `
      Сменить род можно в настройках приложения, откройте боковое меню и нажмите на иконку шестеренки.
    `
  }, {
    title: 'Я нашел ошибку',
    text: `
      Если вы заметили ошибку, пожалуйста, напишите нам на почту info@eshejire.kz или ватсап +7 777 484 6323, мы постараемся её исправить.
    `
  }]
};
