import React, {Component} from 'react';
import {observer} from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import {ModalBack} from './ModalBack';
import {UserSettings} from '../types/models';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {updateUser} from '../services/user.service';

interface UserSettingsProps {
  onClose: () => void;
};

interface UserSettingsState extends UserSettings {};

@observer
export class UserSettingsModal extends Component<UserSettingsProps, UserSettingsState> {
  static contextType = StoreContext;

  constructor(props: UserSettingsProps) {
    super(props);
    this.state = {
      is_receive_messages: false,
      show_location: false,
      female_mode: false
    };
  }

  changeMessage = () => {
    this.setState({is_receive_messages: !this.state.is_receive_messages});
  }

  changeGeo = () => {
    this.setState({show_location: !this.state.show_location});
  }

  changeFem = () => {
    this.setState({female_mode: !this.state.female_mode});
  }

  componentDidMount() {
    const appState = this.context as AppState;
    const user = appState.user.user;
    if(!user) return;
    this.setState({
      is_receive_messages: user.is_receive_messages,
      show_location: user.show_location,
      female_mode: user.female_mode
    });
  }
  
  onSuccess = () => {
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    updateUser(token, langType, this.state).then(() => {
      appState.user.load(token, langType).then(() => this.props.onClose());
    });
  }

  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.settingsModal;
    return (
      <ModalBack onClick={this.props.onClose}>
        <div className="add-card-modal" onClick={(event: any) => event.stopPropagation()}>
          <div className="card-editor-container">
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel control={<Switch color="primary" checked={this.state.is_receive_messages} onChange={this.changeMessage} />} label={lang.msg} />
                <FormControlLabel control={<Switch color="primary" checked={this.state.show_location} onChange={this.changeGeo} />} label={lang.geo} />
                <FormControlLabel control={<Switch color="primary" checked={this.state.female_mode} onChange={this.changeFem} />} label={lang.fem} />
              </FormGroup>
            </FormControl>
          </div>
          <div className="card-editor-save-button" onClick={this.onSuccess}>{lang.save}</div>
          <div className="modal-close-btn" onClick={this.props.onClose}></div>
        </div>
      </ModalBack>
    )
  }
}
