import {LangType} from '../types/lang';

export const saveToken = (token: string): void => {
  localStorage.setItem('api-token', token);
};

export const loadToken = (): string => {
  return localStorage.getItem('api-token') || '';
};

export const clearToken = (): void => {
  localStorage.removeItem('api-token');
};

export const saveLang = (langType: LangType): void => {
  localStorage.setItem('lang', langType);
};

export const loadLang = (): LangType => {
  const langTypeString: string = localStorage.getItem('lang') || '';
  if(langTypeString === LangType[LangType.RU]) return LangType.RU;
  if(langTypeString === LangType[LangType.KZ]) return LangType.KZ;
  return LangType.RU
};

export const clearLang = (): void => {
  localStorage.removeItem('lang');
};
