import React, {FC} from 'react';
import {observer} from 'mobx-react';

import {ModalBack} from './ModalBack';
import {useState} from './Store';

interface EmptyGenusModalProps {
  genus: string;
  onClose: () => void;
  onDemo: () => void;
};

export const EmptyGenusModal: FC<EmptyGenusModalProps> = observer(
  (props: EmptyGenusModalProps) => {
    const appState = useState();
    const lang = appState.lang.lang.tree;
    return (
      <ModalBack onClick={() => props.onClose()}>
        <div className="set-genus-modal" onClick={(event: any) => event.stopPropagation()}>
          <div className="modal-close-btn" onClick={props.onClose}></div>
          <div className="modal-text">
            {lang.emptyMsg1} {props.genus} {lang.emptyMsg2} <a target="_blank" href="mailto:info@eshejire.kz">info@eshejire.kz</a> whatsapp <a target="_blank" href="https://wa.me/77774846323">+7 777 484 63 23</a>
            <br/>
            <a onClick={props.onDemo}>{lang.demo}</a>
          </div>
          <div className="genus-ok-button" onClick={() => props.onClose()}>{lang.cancel}</div>
        </div>
      </ModalBack>
    )
  }
);
