import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';

import {useState} from './Store';

interface FooterMenuProps {};

export const FooterMenu: FC<FooterMenuProps> = observer(
  () => {
    const appState = useState();
    const {menu} = appState.lang.lang;
    return (
      <div className="footer-nav">
        <Link to="/rodnews">{menu.news}</Link>
        <Link to="/history">{menu.history}</Link>
        <Link to="/geneology">{menu.geneology}</Link>
        <Link to="/geo">{menu.geo}</Link>
        <Link to="/chat">{menu.chat}</Link>
        <Link to="/faq">{menu.faq}</Link>
      </div>
    );
  }
);
