import React, {FC} from 'react';
import {observer} from 'mobx-react';

import {ModalBack} from './ModalBack';
import {useState} from './Store';

interface DemoGenusModalProps {
  onClose: () => void;
  onSuccess: () => void;
};

export const DemoGenusModal: FC<DemoGenusModalProps> = observer(
  (props: DemoGenusModalProps) => {
    const appState = useState();
    const lang = appState.lang.lang.tree;
    return (
      <ModalBack onClick={() => props.onClose()}>
        <div className="set-genus-modal" onClick={(event: any) => event.stopPropagation()}>
          <div className="modal-close-btn" onClick={props.onClose}></div>
          <div className="genus-source-title">{appState.lang.lang.tree.source}</div>
          <div className="genus-source-author">Мухамеджан Тынышбаев</div>
          <div className="genus-source-book">"Материалы по истории казахского народа"</div>
          <div className="genus-source-place">Ташкент 1725 год</div>
          <div className="genus-source-isbn">ISBN: 777-7-77-777777</div>
          <div className="genus-ok-button" onClick={() => props.onSuccess()}>{lang.ok}</div>
        </div>
      </ModalBack>
    )
  }
);
