import React, {Component} from 'react';

import {ICahtLastMsg} from '../types/api';
import {isToday, getTime, getMonthDay} from '../services/utils';

interface ChatItemProps {
  chat: ICahtLastMsg;
  onSelect: (chat: ICahtLastMsg) => void;
  isSelected: boolean;
};

interface ChatItemState {};

export class ChatItem extends Component<ChatItemProps, ChatItemState> {

  constructor(props: ChatItemProps) {
    super(props);
  }

  getDateTime = (date: Date): string => {
    return isToday(date) ? getTime(date): getMonthDay(date);
  };
  
  render() {
    const chat = this.props.chat;
    const avatarUrl = chat.avatar || '/images/no-image.png';
    const lastMessage = chat.last_message;
    const date = lastMessage ? new Date(lastMessage.created_at * 1000) : null;
    let messageText = '';
    if(chat.last_message && chat.last_message.type === 'text') {
      messageText = chat.last_message.message;
    }
    return (
      <div className={'chat-item-contact' + (this.props.isSelected ? ' chat-item-contact-active' : '')} onClick={() => this.props.onSelect(this.props.chat)}>
        <div className="img-chat-contact">
          <img src={avatarUrl} alt="" />
        </div>
        <div className="chat-contact-name">
          <p className="contact-name">{chat.name}</p>
          {lastMessage && <p className="massage-more">{messageText}</p>}
        </div>
        <div className="message-time">
          {lastMessage && date && <p className="time">{this.getDateTime(date)}</p>}
        </div>
      </div>
    );
  }
}
