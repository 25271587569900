import axios, { AxiosResponse } from 'axios';

import {IZhuz, IGenus, IGenusList, IShortCardsList, ITooltip} from '../types/api';
import {LangType} from '../types/lang';
import {getLang} from './utils';
import {GenealogyType} from '../types/models';

type GenusesResult = Array<IGenus | IZhuz>;

interface LoadGenusListResult {
  genuses?: GenusesResult;
  message?: string;
  error?: string;
};

export const loadGenusList = async (langType: LangType): Promise<IGenusList> => {
  const lang = getLang(langType);
  const headers = {
    'Accept-Language': lang
  };
  return axios.get<LoadGenusListResult>('/api/v1.0/genus/list', {headers})
    .then((response: AxiosResponse<LoadGenusListResult>) => {
      if(response.data && response.data.genuses) return response.data.genuses;
      if(response.data && response.data.message)
        throw Error(`Load Genuses List error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genuses List error: ${response.data.error}`);
      throw Error(`Load Genuses List error: ${response.statusText}`);
    }).then((genuses: GenusesResult) => {
      const demoGenuses = genuses.filter((genus: IGenus | IZhuz) => genus.type === GenealogyType.Genus);
      return {
        demo: demoGenuses.length === 1 ? demoGenuses[0] : null,
        zhuzes: genuses.filter((genus: IGenus | IZhuz) => genus.type === GenealogyType.Zhuz)
      } as IGenusList;
    });
};

export const loadExistTreeByGenusId = async (genusId: number): Promise<any> => {
  return axios.get<any>(`/api/v1.0/genealogy/tree/json/${genusId}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export const search = (query: string): Promise<any> => {
  return axios.get(`/api/v1.0/genealogy/tree/searchV2?q=${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

interface GenusTreeResult {
  zhuzs?: IZhuz[];
  message?: string;
  error?: string;
};

export const getGenusWithAuthor = (genusId: number): Promise<IZhuz[]> => {
  return axios.get<GenusTreeResult>(`/api/v1.0/genealogy/tree/json/${genusId}`)
    .then((response: AxiosResponse<GenusTreeResult>) => {
      if(response.data && response.data.zhuzs) return response.data.zhuzs;
      if(response.data && response.data.message)
        throw Error(`Load Genus Tree error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Load Genus Tree error: ${response.data.error}`);
      throw Error(`Load Genus Tree error: ${response.statusText}`);
    });
};

interface UpdateUserGenusResult {
  error?: string;
  message?: string;
};

export const updateUserGenus = (genusId: number, token: string, langType: LangType): Promise<void> => {
  const lang = getLang(langType);
  const headers = {
    'Api-Token': token,
    'Accept-Language': lang
  };
  return axios.put<UpdateUserGenusResult>(`/api/v1.0/user/genus`, {genus_id: genusId}, {headers}).then(() => {});
};

export const loadGenusTree = (genusId: number): Promise<string> => {
  return axios.get(`/api/v1.0/cards-tree/genus/${genusId}`).then((response: AxiosResponse<any>) => response.data);
};

export const loadGenusCards = (genusId: number): Promise<IShortCardsList> => {
  return axios.get<IShortCardsList>(`/api/v1.0/genus/${genusId}/cards`)
    .then((response: AxiosResponse) => response.data);
}

interface TooltipsResult {
  result?: ITooltip[];
  message?: string;
  error?: string;
};

export const tooltips = async (langType: LangType, query: string): Promise<ITooltip[]> => {
  const lang = getLang(langType);
  const headers = {
    'Accept-Language': lang,
    'Content-Type': 'application/json'
  };
  return axios.get<TooltipsResult>(`/api/v1.0/genealogy/tree/tooltip?q=${query}`, {headers})
    .then((response: AxiosResponse<TooltipsResult>) => {
      if(response.data && response.data.result) return response.data.result;
      if(response.data && response.data.message)
        throw Error(`Upload Foto Card error: ${response.data.message}`);
      if(response.data && response.data.error)
        throw Error(`Upload Foto Card error: ${response.data.error}`);
      throw Error(`Upload Foto Card error: ${response.statusText}`);
    });
};
