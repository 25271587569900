import React, {Component} from 'react';

import {LayoutInner} from '../components/LayoutInner';
import {Menu} from '../components/Menu';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {observer} from 'mobx-react';

interface FaqItemProps {
  title: string;
  text: string;
};

interface FaqItemState {
  open: boolean;
};

class FaqItem extends Component<FaqItemProps, FaqItemState> {
  
  constructor(props: FaqItemProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggle() {
    this.setState({open: !this.state.open});
  }
  
  render() {
    return (
      <div className="accordion__item" onClick={this.toggle.bind(this)}>
        <div className="accordion__question">
          <h2>{this.props.title}</h2>
        </div>
        {this.state.open ? (
          <div className="accordion__answer">
            <p dangerouslySetInnerHTML={{__html: this.props.text}}></p>
          </div>
        ) : undefined}
      </div>
    );
  }
};

@observer
export class FAQ extends Component {
  static contextType = StoreContext;
  
  // componentDidMount() {
  //   const faq = document.getElementById('faq_frame') as HTMLIFrameElement;
  //   if(!faq) return;
  //   faq.onload = () => {
  //     const wnd = faq.contentWindow;
  //     if(!wnd) return;
  //     faq.style.height = wnd.document.body.scrollHeight + 'px';
  //   };
  // }
  
  render() {
    const
      appState = this.context as AppState,
      faqItems = appState.lang.lang.faqItems;

    return (
      <LayoutInner>
        <section className="content-article">
          <div className="wrap">
            <Menu />
            <div className="main-article-content">
              <h1 className="news-title">Вопрос-ответ</h1>
              <div className="accordion">
                {/* <iframe id="faq_frame" width="100%" src={src} scrolling="no"></iframe> */}
                <div className="accordion__wrapper">
                  {faqItems.map((item: FaqItemProps, index: number) => (<FaqItem title={item.title} text={item.text} key={index}></FaqItem>))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutInner>
    );
  }
};
