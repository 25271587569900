import React, {Component} from 'react';
import {observer} from 'mobx-react';

import {LayoutInner} from '../components/LayoutInner';
import {Menu} from '../components/Menu';
import {getCardGeo, getNearGeo} from '../services/geo.service';
import {AppState} from '../state/app.state';
import {StoreContext} from '../components/Store';
import {IGeo, IPersonalCardFull} from '../types/api';
import {loadCard} from '../services/card.service';

interface GeoProps {};

interface GeoState {
  isLoading: boolean;
  geo: IGeo | null;
  near: IGeo[];
};

@observer
export class Geo extends Component<GeoProps, GeoState> {
  static contextType = StoreContext;

  constructor(props: GeoProps) {
    super(props);
    this.state = {
      isLoading: true,
      geo: null,
      near: []
    };
  }
  
  componentDidMount() {
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType,
      card = appState.card.card;
    const cardId = this.getCardId();
    if(cardId) {
      this.loadByCardId(cardId);
    } else {
      if(card) {
        this.loadByCardId(card.id);
      } else {
        loadCard(token, langType).then((card: IPersonalCardFull) => {
          this.loadByCardId(card.id);
        });
      }
    }
  }

  loadByCardId(cardId: number) {
    const
      appState = this.context as AppState,
      token = appState.auth.token,
      langType = appState.lang.langType;
    getCardGeo(token, langType, cardId).then((geo: IGeo) => {
      this.setState({geo});
      getNearGeo(token, langType, geo).then((near: IGeo[]) => {
        this.setState({near, isLoading: false}, () => {
          (window as any).initMap([...near, geo], geo);
        });
      });
    });
  }

  getCardId(): number | null {
    let cardId: number | null = null;
    const anyProps = this.props as any;
    if(
      anyProps.match &&
      anyProps.match.params &&
      anyProps.match.params.cardId) {
        cardId = parseInt(anyProps.match.params.cardId);
      if(isNaN(cardId)) cardId = null;
    }
    return cardId;
  }
  
  render() {
    const
      appState = this.context as AppState,
      lang = appState.lang.lang.geo;
    return (
      <LayoutInner>
        <section className="content-article">
          <div className="wrap">
            <Menu />
            <div className="main-article-content geo-content">
              <h1 className="news-title">{lang.title}</h1>
              {this.state.isLoading && <div className="spinner2"></div>}
            </div>
          </div>
          <div id="map"></div>
        </section>
      </LayoutInner>
    );
  }
}
