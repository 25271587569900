import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';

import {INewsItem} from '../types/api';
import {useState} from './Store';

export interface NewsItemProps {
  item: INewsItem;
};

export const NewsItem: FC<NewsItemProps> = observer(
  (props: NewsItemProps) => {
    const appState = useState();
    let itemClasses = 'item-news';
    let contentClasses = 'content-news';
    const isImage = !!props.item.photo;
    if(!isImage) {
      itemClasses = `${itemClasses} news-not-images`;
      contentClasses = 'content-news-not-img';
    }
  
    const image = isImage ? (
      <div className="image-news">
        <img src={props.item.photo} alt="" />
      </div>
    ) : null;
    const date = new Date(props.item.created_at * 1000);
    const now = new Date();
    const day = date.getDate();
    const dayStr = day > 9 ? day.toString() : `0${day}`;
    const month = date.getMonth() + 1;
    const monthStr = month > 9 ? month.toString() : `0${month}`;
    const newsDate = `${dayStr}.${monthStr}`;
    const year = date.getFullYear().toString().slice(2, 4);
    const {newsitem} = appState.lang.lang;
    
    return (
      <div className={itemClasses}>
        <div className="date-article">
          <p className="post-date">{newsDate}</p>
          <p className="post-day">{year}</p>
        </div>
        {image}
        <div className={contentClasses}>
          <h1 className="news-title-item">{props.item.name}</h1>
          {/* <div className="news-card__tag">Род Керей</div> */}
          <p className="news-card__description" dangerouslySetInnerHTML={{__html: props.item.short_body}}></p>
          <Link className="more" to={`/article/${props.item.id}`}>{newsitem.full} <img src="images/link-arrow.webp" alt="" /></Link>
        </div>
      </div>
    );
  }
);
